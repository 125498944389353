import React from 'react';
import { Grid, Card, CardContent, Typography, Button, Divider,Box } from '@mui/material';
import Slider from 'react-slick';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
const cardData = [
  {
    id: 1,
    title: '100YR EDITION',
    subtitle: 'ZS EV 100 YR EDITION',
    price: '₹ 24,42,800',
    image: require('../../images/zs/100yredition.webp'), 
    features: [
      '50.3kWh Prismatic Cell Battery',
      'Panoramic Sunroof',
      '461* kms in a single charge',
      'i-Smart with Advanced UI',
    ],
  },
  {
    id: 2,
    title: 'ZS EV EXECUTIVE',
    subtitle: 'MG ZS EV EXECUTIVE',
    price: '₹18,98,000',
    image: require('../../images/zs/exec.webp'),
    features: [
      '25.7cm HD Touchscreen Infotainment',
      'i-SMART & Digital Key with Bluetooth Technology',
      '50.3 kWh Prismatic Cell Battery with 461* kms Range',
      'Bigger, Bolder & Spacious (L: 4323, W: 1809, WB: 2585, H: 1649)',
      '17 Inch Alloy Wheels',
      'Full LED Hawkeye Headlamps',
      'Electric Design Grill',
      'Rear 60:40 Split Seats',
      'Push Button Start/Stop with Smart Entry',
      'Front & Rear Power Windows',
      'Front & Rear Seats Height Adjustable Headrests'
    ],
  },

  {
    id: 3,
    title: 'ZS EV EXCITE PRO',
    subtitle: 'MG ZS EV EXCITE PRO',
    price: '₹19,98,000',
    image: require('../../images/zs/excite.webp'),
    features: [
      'Dual Pane Panoramic Sky roof​',
      'Wireless Android Auto & Apple Carplay',
      'JBL Speaker Audio System (Dealer Fitment)​​',
      'Push Button Start/Stop with Smart Entry & Cruise Control',
      'i-SMART 2.0 with 75+ Connected Car Features​',
      'Digital Bluetooth Key​',
      'Leather Wrapped steering wheel',
      'Silver finish roof rails',
      'Driver & Co-Driver Vanity Mirror',
      'Anti-theft Alarm',
      'Speed Assist'
    ],
  },

  {
    id: 4,
    title: 'ZS EV EXCLUSIVE PLUS',
    subtitle: 'ZS EV EXCLUSIVE PLUS DARK GREY INTERIORS',
    price: '₹ 24,22,800',
    image: require('../../images/zs/plus.webp'),
    features: [
      '360 degree around view camera',
      'Rear Drive Assist (RDA) with Blind Spot Detection System (BSD), Rear Cross Traffic Alert (RCTA) and Lane Change Assist (LCA)',
      'Leather Seats',
      '6 way power adjustable driver seat',
      'Wireless Phone Charging',
      'Rear Centre Armrest with Cup holder ',
      'Wireless Phone Charging',
      'Tyre Pressure Monitoring System (TPMS)',
      'Seatbelt Reminder (All seats)',
      'Rear Seat Armrest with Cup Holder',
      'ABS + EBD + Brake Assist'
    ],
  },
  {
    id: 5,
    title: 'ZS EV EXCLUSIVE PLUS IVORY',
    subtitle: 'MG ZS EV EXCLUSIVE PLUS IVORY INTERIORS',
    price: '₹ 24,43,800',
    image: require('../../images/zs/plus.webp'),
    features: [
      '360 degree around view camera',
      'Rear Drive Assist (RDA) with Blind Spot Detection System (BSD), Rear Cross Traffic Alert (RCTA) and Lane Change Assist (LCA)',
      'Leather Seats',
      '6 way power adjustable driver seat',
      'Wireless Phone Charging',
      'Rear Centre Armrest with Cup holder ',
      'Wireless Phone Charging',
      'Tyre Pressure Monitoring System (TPMS)',
      'Seatbelt Reminder (All seats)',
      'Rear Seat Armrest with Cup Holder',
      'ABS + EBD + Brake Assist'
    ],
  },
  {
    id: 6,
    title: 'ZS EV ESSENCE',
    subtitle: 'MG ZS EV ESSENCE DARK GREY INTERIORS',
    price: '₹ 25,22,800',
    image: require('../../images/zs/plus.webp'),
    features: [
      'ADAS Level 2',
      'Adaptive Cruise Control (ACC)',
      'Bend Cruise Assistance (BCA)',
      'Traffic Jam Assist (TJA)',
      'Lane Departure Warning (LDW)',
      'Emergency Lane Keep (ELK)',
      'Lane Keep Assist (LKA)',
      'Front Collision Warning (FCW)',
      'Automatic Emergency Braking (AEB)',
      'Automatic Emergency Braking-Pedestrian (AEB-P)',
      'Intelligent Hydraulic Braking Assistance (IHBA) (Sub Function of AEB)',
      'Intelligent Headlamp Control (IHC)'
    ],
  },
  {
    id: 7,
    title: 'ZS EV ESSENCE IVORY',
    subtitle: 'MG ZS EV ESSENCE ICONIC IVORY INTERIORS',
    price: '₹ 25,43,800',
    image: require('../../images/zs/plus.webp'),
    features: [
      'ADAS Level 2',
      'Adaptive Cruise Control (ACC)',
      'Bend Cruise Assistance (BCA)',
      'Traffic Jam Assist (TJA)',
      'Lane Departure Warning (LDW)',
      'Emergency Lane Keep (ELK)',
      'Lane Keep Assist (LKA)',
      'Front Collision Warning (FCW)',
      'Automatic Emergency Braking (AEB)',
      'Automatic Emergency Braking-Pedestrian (AEB-P)',
      'Intelligent Hydraulic Braking Assistance (IHBA) (Sub Function of AEB)',
      'Intelligent Headlamp Control (IHC)'
    ],
  },
  
];



const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowForwardIos
      onClick={onClick}
      style={{ 
        color: 'black', 
        fontSize: '30px', 
        position: 'absolute', 
        top: '50%', 
        right: '10px', 
        cursor: 'pointer', 
        zIndex: 1 
      }}
    />
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowBackIos
      onClick={onClick}
      style={{ 
        color: 'black', 
        fontSize: '30px', 
        position: 'absolute', 
        top: '50%', 
        left: '10px', 
        cursor: 'pointer', 
        zIndex: 1 
      }}
    />
  );
};
// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true, // Enable arrows
  nextArrow: <NextArrow />, // Custom next arrow
  prevArrow: <PrevArrow />, // Custom previous arrow
  responsive: [
    {
      breakpoint: 960, // Tablet
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600, // Mobile
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};


const CardGrid = () => {
  // Define card height
  const cardHeight = {md:'170vh',xs:'800px',sm:'1050px',
'@media (min-width: 1024px) and (max-width: 1140px)': {
        height: '120vh',
      },
      '@media (min-width: 1140px) and (max-width: 1340px)': {
        height: '180vh',
      },
      '@media (min-width: 1340px) and (max-width: 1444px)': {
        height: '120vh',
      },
  }; 

  return (
    <Box sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
     
    }}>
      <Typography variant="h2"
        sx={{
          fontSize: { md: '2.08333vw', xs: '4.2vw' },
          color: '#cf2230',
          fontFamily: 'GillSansSemiBold, Arial, sans-serif',
          paddingBottom: '3.125vw',
          fontWeight: '600',
          marginTop: {md:'80px',xs:'40px',sm:'50px'},
          marginBottom:{xs:'10px'}
        }}>
        MG ZS EV Model Variants
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Slider {...settings}>
            {cardData.map((card) => (
              <div key={card.id}>
                <Card
                  sx={{
                    height: cardHeight, 
                    width: {
                      xs: '75%',  // For small screens
                      sm: '87%',  // For medium screens
                      md: '87%',  // For larger screens
                      lg: '75%'  
                    },
                    margin: '5px auto', 
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    boxSizing: 'border-box', 
                    '@media (min-width: 1024px) and (max-width: 1139px)': {
                      width: '300px',
                    },
                  }}
                >
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: 'GillSansRegular, sans-serif',
                        fontSize:  { xs: '4vw', sm: '2.5vw', md: '2vw', lg: '1.27083vw' },
                        color: '#000',
                        marginBottom: '20px',
                      }}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize: { xs: '3vw', sm: '1.6vw', md: '1.5vw', lg: '0.84167vw' },
                        color: '#6e6e6e',
                        marginBottom: { xs: '4vw', sm: '3vw', md: '2vw', lg: '1.0416vw' },
                      }}
                    >
                      {card.subtitle}
                    </Typography>
                    <Button
                      sx={{
                        display: 'inline-block',
                        backgroundColor: '#e1e1e1',
                        borderRadius: '5px',
                        textAlign: 'center',
                        padding: '8px 20px',
                        fontFamily: 'GillSansRegular, sans-serif',
                        fontSize: { xs: '4vw', sm: '2.1vw', md: '1.5vw', lg: '0.8167vw' },
                        color: '#000',
                        marginBottom: { xs: '2.1vw', sm: '2vw', md: '1vw', lg: '1.04167vw' },
                        '&:hover': { backgroundColor: '#d4d4d4' },
                      }}
                    >
                      Book Now
                    </Button>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize:  { xs: '5vw', sm: '2.6vw', md: '2vw', lg: '0.84167vw' },
                        color: '#6e6e6e',
                        marginBottom: { xs: '2vw', sm: '1.5vw', md: '1vw', lg: '.52083vw' },
                      }}
                    >
                      Starts From
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Medium, sans-serif',
                        fontSize: { xs: '5vw', sm: '4vw', md: '3vw', lg: '1.875vw' },
                        color: '#000',
                        marginBottom: 0,
                      }}
                    >
                      {card.price}
                    </Typography>
                    <img
  src={card.image}
  alt={card.subtitle}
  style={{ width: '100%', objectFit: 'cover' }}
  sx={{
    height: { xs: '100px', md: '200px' }, // Adjust the height for different screen sizes
  }}
/>

                    <ul className="points" style={{ flexGrow: 1 }}>
  {card.features.map((feature, index) => (
    <li
      key={index}
      style={{
        fontFamily: 'Roboto-Regular, sans-serif',
        fontSize: '1.04167vw', // Default for larger screens
        color: '#000',
        marginBottom: '1.04167vw',
      }}
    >
      <Typography
        component="span"
        sx={{
          fontWeight:'600',
          fontSize: { xs: '10px', sm: '12px', md: '1.04167vw' }, // Responsive font sizes
          '@media (max-width: 425px)': {
            fontSize: '12px', 
          },
        }}
      >
        {feature}
      </Typography>
    </li>
  ))}
</ul>


                    <Divider style={{ color: '#D4D4D5' }} />
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize: {md:'.9375vw',xs:'10px'},
                        color: '#000',
                        marginTop: '40px',
                        position: 'absolute',
                        bottom: '30px', 
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      Brochure
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
      <Button
        sx={{
          backgroundColor: '#CF2230',
          color: 'white',
          fontWeight: '700',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: 'GillSansSemiBold, sans-serif',
          fontSize: { md: '0.95vw', xs: '1.5vw' },
          marginBottom:'40px',
          cursor: 'pointer',
          marginTop: '50px',
          '&:hover': {
            backgroundColor: 'black',
            color: 'white',
          },
        }}
      >
        Compare Variants
      </Button>
    </Box>
  );
};

export default CardGrid;