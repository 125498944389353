import React, { Component } from "react";
import { connect } from "react-redux";
import NaVBar from "../Components/Nav";

import {
    BookTestDrive
} from "../../../Client/action";
export class Controller extends Component {
    render() {
        return (
            <NaVBar {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        client: store.client,
    };
};
export const mapDispatchToProps = dispatch => {
    return {

        BookTestDrive: (name, email, mobile, modelType, dateTime, address) => {
            dispatch(BookTestDrive(name, email, mobile, modelType, dateTime, address));
        },



    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
