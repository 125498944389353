import React, { Component } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const styles = {
  container: {
    paddingTop: 20,
    paddingBottom: 20,
    width: '100%',
  },
  paper: {
    padding: 20,
    textAlign: 'left',
    backgroundColor: '#f5f5f5',
    width: '100%',
    boxSizing: 'border-box', 
  },
  title: {
    marginBottom: 20,
    fontWeight: 'bold',
  },
  textField: {
    marginBottom: 20,
  },
  button: {
    marginTop: 10,
   display:'flex',
   alignItems:'center'
  },
  formRow: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    marginBottom: '20px',
    flexDirection: 'column', // Stack vertically on smaller screens
    '@media (min-width:600px)': {
      flexDirection: 'row', // Row direction on larger screens
    },
  },
  halfField: {
    flex: 1,
    minWidth: 200, 
  },
  formCheckRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    flexDirection: 'row', 
    '@media (min-width:600px)': {
      flexDirection: 'row', // Row direction on larger screens
    },
  },
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealer: '',
      checked: false,
    };
  }

  handleChange = (event) => {
    this.setState({ dealer: event.target.value });
  };

  handleChanges = (event) => {
    this.setState({ checked: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    const { dealer } = this.state;

    return (
      <>
        <Container
  className={classes.container}
  sx={{
    paddingTop: 2,
    paddingBottom: 2,
  }}
>

          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={8} md={10}>
              <Paper className={classes.paper}>
                <Typography
                  variant="h5"
                  sx={{
                    color: '#CF2230',
                    fontFamily: 'GillSansMedium, sans-serif',
                    textAlign: 'center',
                    marginBottom: '50px',
                    fontSize:{md:'30px',xs:'22px'}
                  }}
                  className={classes.title}
                >
                  Keep In Touch
                </Typography>

                <Box component="form" noValidate autoComplete="off">
                  <div className={classes.formRow}>
                    <div className={classes.halfField}>
                      <Typography
                        sx={{
                          fontFamily: 'GillSansMedium, sans-serif',
                          fontSize: '1pc',
                        }}
                      >
                        First Name<sup style={{ color: 'red', fontSize: '18px' }}>*</sup>
                      </Typography>
                      <TextField
                        fullWidth
                        label="Enter Text here"
                        variant="standard"
                        className={classes.textField}
                      />
                    </div>
                    <div className={classes.halfField}>
                      <Typography
                        sx={{
                          fontFamily: 'GillSansMedium, sans-serif',
                        }}
                      >
                        Last Name<sup style={{ color: 'red', fontSize: '18px' }}>*</sup>
                      </Typography>
                      <TextField
                        fullWidth
                        label="Enter Text here"
                        variant="standard"
                        className={classes.textField}
                      />
                    </div>
                  </div>

                  <div className={classes.formRow}>
                    <div className={classes.halfField}>
                      <Typography
                        sx={{
                          fontFamily: 'GillSansMedium, sans-serif',
                        }}
                      >
                        Mobile Number<sup style={{ color: 'red', fontSize: '18px' }}>*</sup>
                      </Typography>
                      <TextField
                        fullWidth
                        label="Enter Number"
                        type="number"
                        variant="standard"
                        className={classes.textField}
                      />
                    </div>

                    <div className={classes.halfField}>
                      <Typography
                        sx={{
                          fontFamily: 'GillSansMedium, sans-serif',
                        }}
                      >
                        Email ID(name@example.com)<sup style={{ color: 'red', fontSize: '18px' }}>*</sup>
                      </Typography>
                      <TextField
                        fullWidth
                        label="Enter Email ID"
                        variant="standard"
                        className={classes.textField}
                      />
                    </div>
                  </div>

                  <div className={classes.formRow}>
                    <div className={classes.halfField}>
                      <Typography
                        sx={{
                          fontFamily: 'GillSansMedium, sans-serif',
                        }}
                      >
                        Pincode <sup style={{ color: 'red' }}>*</sup>
                      </Typography>
                      <TextField
                        fullWidth
                        label="Enter Pincode"
                        type="number"
                        variant="standard"
                        className={classes.textField}
                      />
                    </div>

                    <div className={classes.halfField}>
                      <Typography
                        sx={{
                          fontFamily: 'GillSansMedium, sans-serif',
                        }}
                      >
                        Dealership<sup style={{ color: 'red' }}>*</sup>
                      </Typography>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                        <InputLabel id="demo-simple-select-standard-label">Select Dealership</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={dealer}
                          onChange={this.handleChange}
                          label="Select dealership"
                        >
                          <MenuItem value="">
                            <em>Select Dealership</em>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className={classes.formRow}>
  <div className={classes.halfField}>
    <Typography
      sx={{
        fontFamily: 'GillSansMedium, sans-serif',
        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Responsive font sizes
      }}
    >
      When do you plan to buy? <sup style={{ color: 'red' }}>*</sup>
    </Typography>
    <FormControl
      variant="standard"
      sx={{
        m: 1,
        minWidth: { xs: '100%', sm: '100%', md: 420 }, // Responsive width
      }}
    >
      <InputLabel id="demo-simple-select-standard-label">Select Days</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={dealer}
        onChange={this.handleChange}
        label="Select Days"
      >
        <MenuItem value="">
          <em>Select Days</em>
        </MenuItem>
        <MenuItem value="15">
          <em>0-15 days</em>
        </MenuItem>
        <MenuItem value="30">
          <em>15-30 days</em>
        </MenuItem>
        <MenuItem value="60">
          <em>30-60 days</em>
        </MenuItem>
        <MenuItem value="90">
          <em>60-90 days</em>
        </MenuItem>
      </Select>
    </FormControl>
  </div>
</div>


<div className={classes.formCheckRow}>
  <Checkbox
    {...label}
    checked={this.state.checked1}
    onChange={(e) => this.setState({ checked1: e.target.checked })}
    sx={{ marginRight: '2px' }} // Reduce the gap to 2px
  />
  <Typography
    sx={{
      fontSize: '9pt',
      fontFamily: 'GillSans, sans-serif',
    }}
  >
    I authorize JSW MG Motor India Private Limited the consent to save my contact details for future connect through call, email, SMS or WhatsApp.
  </Typography>
</div>

<div className={classes.formCheckRow}>
  <Checkbox
    {...label}
    checked={this.state.checked2}
    onChange={(e) => this.setState({ checked2: e.target.checked })}
    sx={{ marginRight: '2px' }} 
  />
  <Typography
    sx={{
      fontSize: '9pt',
      fontFamily: 'GillSans, sans-serif',
    }}
  >
    Want to have a virtual tour of the car?
  </Typography>
</div>

<Box
  sx={{
    display: 'flex',
    justifyContent: 'center', // Centers horizontally
    marginTop: '20px', // Adjust the margin if needed
  }}
>
  <Button
    className={classes.button}
    sx={{
      backgroundColor: '#CF2230',
      color: 'white',
      fontWeight: '700',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'GillSansSemiBold, sans-serif',
      fontSize: {md:'0.95vw',xs:'2.56vw'},
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'black',
        color: 'white',
      },
    }}
  >
    Submit
  </Button>
</Box>

                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}

export default withStyles(styles)(ContactUs);
