import React from 'react';
import { Grid, Card, CardContent, Typography, Button, Divider,Box } from '@mui/material';
import Slider from 'react-slick';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
const cardData = [
  {
    id: 1,
    title: 'SNOW STORM 7 SEATER',
    subtitle: 'DIESEL 2.0 8AT 4X2 TURBO',
    price: '₹41,04,800',
    image: require('../../../../images/storm/storm1.webp'), 
    features: [
      'Dual Pane Panoramic Sunroof',
      'Adaptive Cruise Control​',
      'Automatic Parking Assist​',
      'Electronic Gear Shift with Auto Park​',
      'Intelligent 4X4 with all Terrain Systems (7 modes) in 4WD only​'
    ],
  },
  {
    id: 2,
    title: 'SNOW STORM 7 SEATER',
    subtitle: 'DIESEL 2.0 8AT 4X4 TWIN TURBO',
    price: '₹43,86,800',
    image: require('../../../../images/storm/snow.webp'),
    features: [
      'Drive Modes (Sports/Normal/Eco)​',
      '12 Way Power Adjustment Seat (including 4 Lumbar Adjustment)​',
      'Roll Movement Intervention (RMI)​',
      'Electromechanical Differential Lock (EDL) in 4 WD only​​',
      'Rear Cross Traffic Alert (RCTA)​'
    ],
  },

  {
    id: 3,
    title: 'DESERT STORM 6 SEATER',
    subtitle: 'DIESEL 2.0 8AT 4X2 TURBO',
    price: '₹41,04,800',
    image: require('../../../../images/storm/desert.webp'),
    features: [
      'Dual Pane Panoramic Sunroof',
      'Automatic Parking Assist (APA)',
      'Electronic Gear Shift with Auto Park',
      'Intelligent 4WD with All Terrain System (7 modes)',
      'Driver Seat 12 Way Power Adjustment seat (including 4 Lumbar Adjustment)'
    ],
  },

  {
    id: 4,
    title: 'DESERT STORM 6 SEATER',
    subtitle: 'DIESEL 2.0 8AT 4X4 TWIN TURBO',
    price: '₹43,86,800',
    image: require('../../../../images/storm/desert2.webp'),
    features: [
      'Drive Modes (Sports/Normal/Eco)​​',
      '12 Way Power Adjustment Seat (including 4 Lumbar Adjustment)​​',
      'Roll Movement Intervention (RMI)​​',
      'Electromechanical Differential Lock (EDL) in 4 WD only​​​',
      'Rear Cross Traffic Alert (RCTA)​',
    ],
  },
  {
    id: 5,
    title: 'DESERT STORM 7 SEATER',
    subtitle: 'DIESEL 2.0 8AT 4X2 TURBO',
    price: '₹41,04,800',
    image: require('../../../../images/storm/desert3.webp'),
    features: [
      'Forward Collision Warning (FCW)​',
      'Automatic Emergency Braking (AEB)​',
      'Lane Departure Warning (LDW)​',
      'Blind Spot Detection (BSD)​',
      'Lane Change Assist (LCA)​',
    ],
  },
  {
    id: 6,
    title: 'DESERT STORM 7 SEATER',
    subtitle: 'DIESEL 2.0 8AT 4X4 TWIN TURBO',
    price: '₹43,86,800',
    image: require('../../../../images/storm/desert3.webp'),
    features: [
      'Drive Modes (Sports/Normal/Eco)​',
      '12 Way Power Adjustment Seat (including 4 Lumbar Adjustment)​',
      'Roll Movement Intervention (RMI)​',
      'Electromechanical Differential Lock (EDL) in 4 WD only​​',
      'Rear Cross Traffic Alert(RCTA)'
    ],
  },
  {
    id: 7,
    title: 'BLACK STORM 6 SEATER',
    subtitle: 'GLOSTER 4X4 6S ADAS',
    price: '₹41,04,800',
    image: require('../../../../images/storm/black.webp'),
    features: [
      'Dual Pane Panoramic Sunroof',
      'Adaptive Cruise Control​',
      'Electronic Gear Shift with Auto Park',
      'Automatic Parking Assist​',
      'Intelligent 4X4 with all Terrain Systems (7 modes) in 4WD only​'
    ],
  },
  {
    id: 8,
    title: 'BLACK STORM 6 SEATER',
    subtitle: 'GLOSTER 4X4 6S ADAS',
    price: '₹43,86,800',
    image: require('../../../../images/storm/black2.webp'),
    features: [
      'Drive Modes (Sports/Normal/Eco)​',
      '12 Way Power Adjustment Seat (including 4 Lumbar Adjustment)​',
      'Roll Movement Intervention (RMI)​',
      'Electromechanical Differential Lock (EDL) in 4 WD only​​​',
      'Rear Cross Traffic Alert (RCTA)​',
    ],
  },
  {
    id: 9,
    title: 'BLACK STORM 7 SEATER',
    subtitle: 'GLOSTER 4X2 7S ADAS',
    price: '₹41,04,800',
    image: require('../../../../images/storm/black3.webp'),
    features: [
      'Automatic Emergency Braking (AEB)​',
      'Lane Departure Warning (LDW)​',
      'Blind Spot Detection (BSD)​',
      'Lane Change Assist (LCA)​',
      'Rear Cross Traffic Alert (RCTA)'
    ],
  },
  {
    id: 10,
    title: 'BLACK STORM 7 SEATER',
    subtitle: 'GLOSTER 4X4 7S ADAS',
    price: '₹43,86,800',
    image: require('../../../../images/storm/black4.webp'),
    features: [
      'Automatic Emergency Braking (AEB)​',
      'Lane Departure Warning (LDW)​',
      'Blind Spot Detection (BSD)​',
      'Lane Change Assist (LCA)​',
      'Rear Cross Traffic Alert (RCTA)'
    ],
  },
];



const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowForwardIos
      onClick={onClick}
      style={{ 
        color: 'black', 
        fontSize: '30px', 
        position: 'absolute', 
        top: '50%', 
        right: '10px', 
        cursor: 'pointer', 
        zIndex: 1 
      }}
    />
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowBackIos
      onClick={onClick}
      style={{ 
        color: 'black', 
        fontSize: '30px', 
        position: 'absolute', 
        top: '50%', 
        left: '10px', 
        cursor: 'pointer', 
        zIndex: 1 
      }}
    />
  );
};
// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true, // Enable arrows
  nextArrow: <NextArrow />, // Custom next arrow
  prevArrow: <PrevArrow />, // Custom previous arrow
  responsive: [
    {
      breakpoint: 960, // Tablet
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600, // Mobile
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};


const CardGrid = () => {
  // Define card height
  const cardHeight = {md:'780px',xs:'650px',sm:'870px'}; 

  return (
    <Box sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      
    }}>
      <Typography variant="h2"
        sx={{
          fontSize: { md: '2.08333vw', xs: '4.2vw' },
          color: '#cf2230',
          fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
          paddingBottom: '3.125vw',
          fontWeight: '600',
          marginTop: {md:'80px',xs:'40px',sm:'50px'},
          marginBottom:{xs:'10px'}
        }}>
        MG Gloster Model Variants
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Slider {...settings}>
            {cardData.map((card) => (
              <div key={card.id}>
                <Card
                  sx={{
                    height: cardHeight, 
                    width: {
                      xs: '75%',  // For small screens
                      sm: '87%',  // For medium screens
                      md: '80%',  // For larger screens
                      lg: '70%'   // For very large screens
                    },
                    margin: '5px auto', 
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    boxSizing: 'border-box', 
                    '@media (min-width: 1024px) and (max-width: 1139px)': {
                      width: '300px',
                    },
                  }}
                >
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: 'GillSansRegular, sans-serif',
                        fontSize: { xs: '4vw', sm: '2.5vw', md: '2vw', lg: '1.27083vw' },
                        color: '#000',
                        marginBottom: '20px',
                      }}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize:  { xs: '3vw', sm: '1.6vw', md: '1.5vw', lg: '0.84167vw' },
                        color: '#6e6e6e',
                        marginBottom: { xs: '4vw', sm: '3vw', md: '2vw', lg: '1.0416vw' },
                      }}
                    >
                      {card.subtitle}
                    </Typography>
                    <Button
                      sx={{
                        display: 'inline-block',
                        backgroundColor: '#e1e1e1',
                        borderRadius: '5px',
                        textAlign: 'center',
                        padding: '8px 20px',
                        fontFamily: 'GillSansRegular, sans-serif',
                        fontSize:{ xs: '4vw', sm: '2.1vw', md: '1.5vw', lg: '0.8167vw' },
                        color: '#000',
                        marginBottom: { xs: '2.1vw', sm: '2vw', md: '1vw', lg: '1.04167vw' },
                        '&:hover': { backgroundColor: '#d4d4d4' },
                      }}
                    >
                      Book Now
                    </Button>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize: { xs: '5vw', sm: '2.6vw', md: '2vw', lg: '0.84167vw' },
                        color: '#6e6e6e',
                        marginBottom: { xs: '2vw', sm: '1.5vw', md: '1vw', lg: '.52083vw' },
                      }}
                    >
                      Starts From
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Medium, sans-serif',
                        fontSize: { xs: '5vw', sm: '4vw', md: '3vw', lg: '1.875vw' },
                        color: '#000',
                        marginBottom: 0,
                      }}
                    >
                      {card.price}
                    </Typography>
                    <img
  src={card.image}
  alt={card.subtitle}
  style={{ width: '100%', objectFit: 'cover' }}
  sx={{
    height: { xs: '100px', md: '200px' }, // Adjust the height for different screen sizes
  }}
/>

                    <ul className="points" style={{ flexGrow: 1 }}>
  {card.features.map((feature, index) => (
    <li
      key={index}
      style={{
        fontFamily: 'Roboto-Regular, sans-serif',
        fontSize: '1.04167vw', // Default for larger screens
        color: '#000',
        marginBottom: '1.04167vw',
      }}
    >
      <Typography
        component="span"
        sx={{
          fontWeight:'600',
          fontSize: { xs: '10px', sm: '12px', md: '1.04167vw' }, // Responsive font sizes
          '@media (max-width: 425px)': {
            fontSize: '12px', 
          },
        }}
      >
        {feature}
      </Typography>
    </li>
  ))}
</ul>


                    <Divider style={{ color: '#D4D4D5' }} />
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize: {md:'.9375vw',xs:'10px'},
                        color: '#000',
                        marginTop: '40px',
                        position: 'absolute',
                        bottom: '30px', 
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      Brochure
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
      <Button
        sx={{
          backgroundColor: '#CF2230',
          color: 'white',
          fontWeight: '700',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: 'GillSansSemiBold, sans-serif',
          fontSize: { md: '0.95vw', xs: '1.5vw' },
          marginBottom:'40px',
          cursor: 'pointer',
          marginTop: '50px',
          '&:hover': {
            backgroundColor: 'black',
            color: 'white',
          },
        }}
      >
        Compare Variants
      </Button>
    </Box>
  );
};

export default CardGrid;
