import React from 'react';
import { Grid, Card, CardContent, Typography, Button, Divider,Box } from '@mui/material';
import Slider from 'react-slick';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
const cardData = [
  {
    id: 1,
    title: '100Y EDITION',
    subtitle: 'HECTOR CVT 100Y EDITION',
    price: '₹ 21,40,800',
    image: require('../../../../images/hector-cvt.webp'), 
    features: [
      '35.56 cm (14 inch) HD Infotainment System',
      'Digital Bluetooth Key Sharing',
      'Panoramic Sunroof',
      'i-Smart with Advanced UI',
    ],
  },
  {
    id: 2,
    title: '100Y EDITION',
    subtitle: 'HECTOR DSL 6MT 100Y EDITION',
    price: '₹ 22,11,800',
    image: require('../../../../images/dsl.webp'),
    features: [
      '35.56 cm (14 inch) HD Infotainment System',
      'Digital Bluetooth Key Sharing',
      'Panoramic Sunroof',
      'i-Smart with Advanced UI',
    ],
  },

  {
    id: 3,
    title: 'BLACKSTORM',
    subtitle: 'HECTOR BLACKSTORM CVT',
    price: '₹21,52,800',
    image: require('../../../../images/hector-blackstorm.webp'),
    features: [
      'R 18 Sporty All Black Alloy Wheels',
      'Argyle Inspired Diamond Mesh Dark Chrome Grille',
      'All Black Interior Theme with Gun Metal Grey Accents',
      'Digital Bluetooth Key with Key Sharing function',
      '100 + Voice commands to control  Sunroof, AC & More',
      '35.56 cm HD Portrait Infotainment System',
      'Dual Pane Panoramic Sunroof',
      'Front Ventilated Seats',
    ],
  },

  {
    id: 4,
    title: 'BLACKSTORM',
    subtitle: 'HECTOR BLACKSTORM DSL 6MT',
    price: '₹22,23,800',
    image: require('../../../../images/blackstormdsl.webp'),
    features: [
      'R 18 Sporty All Black Alloy Wheels',
      'Argyle Inspired Diamond Mesh Dark Chrome Grille',
      'All Black Interior Theme with Gun Metal Grey Accents',
      'Digital Bluetooth Key with Key Sharing function',
      '100 + Voice commands to control  Sunroof, AC & More',
      '35.56 cm HD Portrait Infotainment System',
      'Dual Pane Panoramic Sunroof',
      'Front Ventilated Seats',
    ],
  },
  {
    id: 5,
    title: 'SAVVY PRO',
    subtitle: 'HECTOR SAVVY PRO CVT',
    price: '₹22,16,800',
    image: require('../../../../images/saavy.webp'),
    features: [
      '360 Around View High Definition (HD) Camera System',
      'Curtain Airbags',
      'Rain Sensing Wipers',
      'Front Ventilated Seats',
      'Automatic Powered Tailgate Opening',
      '4-way Power Adjustable Co-Driver Seat',
      '8 Color Ambient Lighting with Voice Commands',
      'Premium Sound System by Infinity',
      'ADAS Level 2'
    ],
  },
  {
    id: 6,
    title: 'STYLE',
    subtitle: 'HECTOR STYLE 6MT',
    price: '₹13,98,800',
    image: require('../../../../images/style.webp'),
    features: [
      'Electronic Stability Program (ESP)',
      'Hill Hold Control',
      'ABS + EBD',
      'All 4 Disc Brakes​',
      'Height Adjustable Driver',
      'Power Adjustable ORVM',
      '60:40 Split 2nd Row Seats',
      '2nd Row AC Vents​',
      'All Power Window with Driver Side One Touch Down',
      'LED DRLs​',
      'Audio System + USB + FM + Bluetooth',
      '4 Speakers​'
    ],
  },
  {
    id: 7,
    title: 'SHARP PRO',
    subtitle: 'HECTOR SHARP PRO CVT',
    price: '₹21,20,800',
    image: require('../../../../images/sharp1.webp'),
    features: [
      '360 Around View High Definition (HD) Camera System',
      'Curtain Airbags',
      'Rain Sensing Wipers',
      'Front Ventilated Seats​',
      'Automatic Powered Tailgate Opening',
      '4-way Power Adjustable Co-Driver Seat',
      '8 Color Ambient Lighting with Voice Commands',
      'Premium Sound System by Infinity',
      
    ],
  },
  {
    id: 8,
    title: 'SHARP PRO',
    subtitle: 'HECTOR SHARP PRO DSL 6MT',
    price: '₹21,91,800',
    image: require('../../../../images/sharp1.webp'),
    features: [
      '360 Around View High Definition (HD) Camera System',
      'Curtain Airbags',
      'Rain Sensing Wipers',
      'Front Ventilated Seats​',
      'Automatic Powered Tailgate Opening',
      '4-way Power Adjustable Co-Driver Seat',
      '8 Color Ambient Lighting with Voice Commands',
      'Premium Sound System by Infinity',
      
    ],
  },
  {
    id: 9,
    title: 'SHARP PRO',
    subtitle: 'HECTOR SHARP PRO 6MT',
    price: '₹19,89,800',
    image: require('../../../../images/sharp1.webp'),
    features: [
      '360 Around View High Definition (HD) Camera System',
      'Curtain Airbags',
      'Rain Sensing Wipers',
      'Front Ventilated Seats​',
      'Automatic Powered Tailgate Opening',
      '4-way Power Adjustable Co-Driver Seat',
      '8 Color Ambient Lighting with Voice Commands',
      'Premium Sound System by Infinity',
      
    ],
  },
  {
    id: 10,
    title: 'SMART PRO',
    subtitle: 'HECTOR SMART PRO DSL 6MT',
    price: '₹19,99,800',
    image: require('../../../../images/smartpro.webp'),
    features: [
      'Front Parking Sensors',
      'Front Side Airbags',
      'Tyre Pressure Monitoring System',
      'Digital key​',
      '6-way Power Adjustable Driver Seat',
      'Wireless Charger',
      'Auto-dimming IRVM',
      'Power Foldable ORVM',
      'Dual Tone Oak White & Black Interior Theme',
      'Full Digital Cluster',
      'Leather Upholstery',
      '35.56 cm HD Portrait Infotainment System',
      'Wireless Android Auto and Apple Carplay'
      
    ],
  },
  {
    id: 11,
    title: 'SMART PRO',
    subtitle: 'HECTOR SMART PRO 6MT',
    price: '₹18,42,800',
    image: require('../../../../images/smartpro.webp'),
    features: [
      'Front Parking Sensors',
      'Front Side Airbags',
      'Tyre Pressure Monitoring System',
      'Digital key​',
      '6-way Power Adjustable Driver Seat',
      'Wireless Charger',
      'Auto-dimming IRVM',
      'Power Foldable ORVM',
      'Dual Tone Oak White & Black Interior Theme',
      'Full Digital Cluster',
      'Leather Upholstery',
      '35.56 cm HD Portrait Infotainment System',
      'Wireless Android Auto and Apple Carplay'
      
    ],
  },
  {
    id: 12,
    title: 'SELECT PRO',
    subtitle: 'HECTOR SELECT PRO DSL 6MT',
    price: '₹18,88,800',
    image: require('../../../../images/selectpro1.webp'),
    features: [
      'Dual pane Panoramic Sunroof',
      'Push Button Start/Stop with Smart Entry',
      '3rd Row AC with Separate Fan Speed Control (7/6 Seater)',
      'Floating Turn Indicators',
      'LED Blade Connecting Tail lights',
      'R18 Dual Tone Machined Alloy Wheels',
      'Leather Wrapped Steering Wheel',
      'i-Smart technology',
      'Rear Tweeters',
      'Subwoofer and Amplifier',

    ],
  },
  {
    id: 13,
    title: 'SELECT PRO',
    subtitle: 'HECTOR SELECT PRO CVT',
    price: '₹18,67,800',
    image: require('../../../../images/selectpro1.webp'),
    features: [
      'Reverse Parking Camera',
      'Electric Parking Brake (CVT)',
      'Electric Sunroof',
      'Drive Modes (CVT)',
      'Push Button Start/Stop with Smart Entry (CVT)',
      'Cruise Control',
      'AC Controls on Headunit',
      'LED Projector Headlamps',
      'LED Tail lamps',
      'R17 Alloy Wheels',
      'Argyle Inspired Diamond mesh Grille',
      'Android Auto and Apple Carplay',
      '26.41 cm HD Infotainment System'
    ],
  },
  {
    id: 14,
    title: 'SELECT PRO',
    subtitle: 'HECTOR SELECT PRO 6MT',
    price: '₹17,47,800',
    image: require('../../../../images/selectpro2.webp'),
    features: [
      'Dual pane Panoramic Sunroof',
      'Push Button Start/Stop with Smart Entry',
      'Floating Turn Indicators',
      'LED Blade Connecting Tail lights',
      'R18 Dual Tone Machined Alloy Wheels',
      'Leather Wrapped Steering Wheel',
      'i-Smart technology',
      'Rear Tweeters',
      'Subwoofer and Amplifier',

    ],
  },
  {
    id: 15,
    title: 'SHINE PRO',
    subtitle: 'HECTOR SHINE PRO CVT',
    price: '₹17,16,800 ',
    image: require('../../../../images/shinepro.webp'),
    features: [
      'Reverse Parking Camera',
      'Electric Parking Brake (CVT)',
      'Electric Sunroof',
      'Drive Modes (CVT)',
      'Push Button Start/Stop with Smart Entry (CVT)',
      'Cruise Control',
      'AC Controls on Headunit',
      'LED Projector Headlamps',
      'LED Tail lamps',
      'R17 Alloy Wheels',
      'Argyle Inspired Diamond mesh Grille',
      'Android Auto and Apple Carplay',
      '26.41 cm HD Infotainment System',
    ],
  },
  {
    id: 15,
    title: 'SHINE PRO',
    subtitle: 'HECTOR SHINE PRO 6MT',
    price: '₹16,15,800 ',
    image: require('../../../../images/shinepro.webp'),
    features: [
      'India’s Largest 35.56cm  HD Portrait HD Screen (14”)',
      'All Black Interiors',
      'Electric Sunroof',
      'R17 Alloy Wheels',
      'Reverse Parking Camera',
      'Intelligent Turn Indicators',
      'Push Button Start/Stop with Smart Entry',
      'Electric Parking Brake (CVT)',
      'Cruise Control',
      'Wireless Android Auto and Apple Carplay',
      'Wireless Charger',
      'Full Digital Cluster with 17.78 cm Embedded LCD Screen',
      '6 Speakers + Tweeters',
    ],
  },
  {
    id: 17,
    title: 'SHINE PRO',
    subtitle: 'HECTOR SHINE PRO DSL 6MT',
    price: '₹17,87,800 ',
    image: require('../../../../images/shinepro.webp'),
    features: [
      'Reverse Parking Camera',
      'Electric Parking Brake (CVT)',
      'Electric Sunroof',
      'Drive Modes (CVT)',
      'Push Button Start/Stop with Smart Entry (CVT)',
      'Cruise Control',
      'AC Controls on Headunit',
      'LED Projector Headlamps',
      'LED Tail lamps',
      'R17 Alloy Wheels',
      'Argyle Inspired Diamond mesh Grille',
      'Android Auto and Apple Carplay',
      '26.41 cm HD Infotainment System',
    ],
  },
  
];



const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowForwardIos
      onClick={onClick}
      style={{ 
        color: 'black', 
        fontSize: '30px', 
        position: 'absolute', 
        top: '50%', 
        right: '10px', 
        cursor: 'pointer', 
        zIndex: 1 
      }}
    />
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowBackIos
      onClick={onClick}
      style={{ 
        color: 'black', 
        fontSize: '30px', 
        position: 'absolute', 
        top: '50%', 
        left: '10px', 
        cursor: 'pointer', 
        zIndex: 1 
      }}
    />
  );
};
// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true, 
  nextArrow: <NextArrow />, 
  prevArrow: <PrevArrow />, 
  responsive: [
    {
      breakpoint: 960, // Tablet
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600, // Mobile
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};


const CardGrid = () => {
  // Define card height
  const cardHeight = {md:'1200px',xs:'750px',sm:'1150px'}; 

  return (
    <Box sx={{
      height:'100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent:'center',
    }}>
      <Typography variant="h2"
        sx={{
          fontSize: { md: '2.08333vw', xs: '4.2vw' },
          color: '#cf2230',
          fontFamily: 'GillSansSemiBold, Arial, sans-serif',
          paddingBottom: '1.125vw',
          fontWeight: '600',
          marginTop: {md:'80px',xs:'40px',sm:'50px'},
          marginBottom:{xs:'10px'}
        }}>
        MG Hector Model Variants
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Slider {...settings}>
            {cardData.map((card) => (
              <div key={card.id}>
                <Card
                  sx={{
                    height: cardHeight, 
                    width: {
                      xs: '75%',  // For small screens
                      sm: '87%',  // For medium screens
                      md: '80%',  // For larger screens
                      lg: '70%'   // For very large screens
                    },
                    margin: '5px auto', 
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    boxSizing: 'border-box', 
                    '@media (min-width: 1024px) and (max-width: 1139px)': {
                      width: '300px',
                    },
                  }}
                >
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: 'GillSansRegular, sans-serif',
                        fontSize: { xs: '4vw', sm: '2.5vw', md: '2vw', lg: '1.27083vw' },
                        color: '#000',
                        marginBottom: '20px',
                      }}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize:  { xs: '3vw', sm: '1.6vw', md: '1.5vw', lg: '0.84167vw' },
                        color: '#6e6e6e',
                        marginBottom: { xs: '4vw', sm: '3vw', md: '2vw', lg: '1.0416vw' },
                      }}
                    >
                      {card.subtitle}
                    </Typography>
                    <Button
                      sx={{
                        display: 'inline-block',
                        backgroundColor: '#e1e1e1',
                        borderRadius: '5px',
                        textAlign: 'center',
                        padding: '8px 20px',
                        fontFamily: 'GillSansRegular, sans-serif',
                        fontSize: { xs: '4vw', sm: '2.1vw', md: '1.5vw', lg: '0.8167vw' },
                        color: '#000',
                        marginBottom: { xs: '2.1vw', sm: '2vw', md: '1vw', lg: '1.04167vw' },
                        '&:hover': { backgroundColor: '#d4d4d4' },
                      }}
                    >
                      Book Now
                    </Button>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize:{ xs: '5vw', sm: '2.6vw', md: '2vw', lg: '0.84167vw' },
                        color: '#6e6e6e',
                        marginBottom: { xs: '2vw', sm: '1.5vw', md: '1vw', lg: '.52083vw' },
                      }}
                    >
                      Starts From
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Medium, sans-serif',
                        fontSize: { xs: '5vw', sm: '4vw', md: '3vw', lg: '1.875vw' },
                        color: '#000',
                        marginBottom: 0,
                      }}
                    >
                      {card.price}
                    </Typography>
                    <img
  src={card.image}
  alt={card.subtitle}
  style={{ width: '100%', objectFit: 'cover' }}
  sx={{
    height: { xs: '100px', md: '200px' }, // Adjust the height for different screen sizes
  }}
/>

                    <ul className="points" style={{ flexGrow: 1 }}>
  {card.features.map((feature, index) => (
    <li
      key={index}
      style={{
        fontFamily: 'Roboto-Regular, sans-serif',
        fontSize: '1.04167vw', // Default for larger screens
        color: '#000',
        marginBottom: '1.04167vw',
      }}
    >
      <Typography
        component="span"
        sx={{
          fontWeight:'600',
          fontSize: { xs: '10px', sm: '12px', md: '1.04167vw' }, // Responsive font sizes
          '@media (max-width: 425px)': {
            fontSize: '12px', 
          },
        }}
      >
        {feature}
      </Typography>
    </li>
  ))}
</ul>


                    <Divider style={{ color: '#D4D4D5' }} />
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize: {md:'.9375vw',xs:'10px'},
                        color: '#000',
                        marginTop: '40px',
                        position: 'absolute',
                        bottom: '30px', 
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      Brochure
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
      <Button
        sx={{
          backgroundColor: '#CF2230',
          color: 'white',
          fontWeight: '700',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: 'GillSansSemiBold, sans-serif',
          fontSize: { md: '0.95vw', xs: '1.5vw' },
          marginBottom:'40px',
          cursor: 'pointer',
          marginTop: '50px',
          '&:hover': {
            backgroundColor: 'black',
            color: 'white',
          },
        }}
      >
        Compare Variants
      </Button>
    </Box>
  );
};

export default CardGrid;
