import { Component } from "react";
import React from "react";
import "./About.css";
import {
  Box,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import Navbar from "../../Home/Components/Nav";
import Footer from "../../Home/Components/Footer";
import heritage from "../../images/aboutheritage.webp";
import company from '../../images/parentcompany.webp'
import operations from "../../images/opeartions.webp";
import girl from '../../images/girledu.webp';
import roadsafety from '../../images/roadsafety.jpg'
import history from '../../images/history.jpg';
import innovation from '../../images/innovation.webp';
import brand from '../../images/brand.webp'
class About extends Component {
 
  render() {
   
    return (
      <div id="container">
        <Navbar />
        {/* Banner */}
        <div id="banner-image-about">
          <div id="write">
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: { md: "70px", xs: "16px", sm: "50px" },
                position: "relative",
                marginTop:{md:'350px',sm:'200px',xs:'180px'},
                bottom: "50px",
                color:'white'
              }}
            >
              DEDICATED TO MOTORING EXPERIENCES SINCE 1924
            </Typography>
          </div>
        </div>
        {/* Heritage */}
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={heritage}
                alt="heritage"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                OUR BRAND LINEAGE
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "16px", md: "16px" },
                  marginBottom: "20px",
                  width:{md:'550px'}
                }}
              >
              The love for MGs is infectious. No doubt about it when you realize, we are a 100-year-old start up. Why a start up? Because we were founded in a garage called ‘Morris Garages’ in 1924 by William Morris. Today, we are a future forward brand
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#D23132",
                    width: "200px",
                    fontWeight: "bolder",
                  }}
                >
                  Know More
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Parent Company */}
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                    OUR PARENT COMPANY
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "16px", md: "16px" },
                  marginBottom: "20px",
                  width:{md:'550px'}
                }}
              >
              MG has made its way, back to India under the leadership of the SAIC Motor Corporation Limited, the world's 7th largest automobile company with 36th ranking in the Fortune 500 list.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#D23132",
                    width: "200px",
                    fontWeight: "bolder",
                  }}
                >
                  Know More
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={company}
                alt="parentcompany"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* operations */}
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={operations}
                alt="operations"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                INDIA OPERATIONS
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "16px", md: "16px" },
                  marginBottom: "20px",
                  width:{md:'550px'}
                }}
              >
              With the inauguration of its first manufacturing facility in September 2017 in Halol, Gujarat, MG is now fully operational in India. If the future of the automobile truly excites you, follow us on our digital channels to stay updated.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#D23132",
                    width: "200px",
                    fontWeight: "bolder",
                  }}
                >
                  Know More
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Girl child educations */}
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                    GIRL CHILD EDUCATION
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "16px", md: "16px" },
                  marginBottom: "20px",
                  width:{md:'550px'}
                }}
              >
              MG has joined hands with IIMPACT to provide access to quality education to at least 1,000 girl children in 2019. IIMPACT is an NGO engaged in providing quality education to the girl child through its learning centres in remote villages of India.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#D23132",
                    width: "200px",
                    fontWeight: "bolder",
                  }}
                >
                  Know More
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={girl}
                alt="girledu"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Road Safety */}
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={roadsafety}
                alt="safety"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                ROAD SAFETY
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "16px", md: "16px" },
                  marginBottom: "20px",
                  width:{md:'550px'}
                }}
              >
             As part of the commitment towards road safety, Haryana Government, MG Motor India and TRAX NGO have joined hands to train teachers and educate school students in Gurugram and Faridabad to focus on spreading road safety awareness.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#D23132",
                    width: "200px",
                    fontWeight: "bolder",
                  }}
                >
                  Know More
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Image gallery */}
        <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid 
        container 
        spacing={1} 
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={4} md={2.5}>
          <Box textAlign="center">
            <img src={history} alt="history" width={250} height={150} />
            <Typography variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",}}>HISTORY</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={2.5}>
          <Box textAlign="center">
            <img src={innovation} alt="innovation" width={250} height={150} />
            <Typography variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",}}>INNOVATIONS</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={2.5}>
          <Box textAlign="center">
            <img src={brand} alt="brand" width={250} height={150} />
            <Typography variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",}}>BRAND PILLARS</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>      
        {/* Footer Section */}
        <Footer />
      </div>
    );
  }
}

export default About;
