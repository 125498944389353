import React, { Component } from "react";
import { connect } from "react-redux";
import TestDrive from "../Components/testDrive";

import {
  viewAllTestDrive,
  updateTestDriveStatus,
  deleteTestDrive,
} from "../action";
export class Controller extends Component {
  render() {
    return <TestDrive {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllTestDrive: (
      token,
      user_id,
      searchInput,
      page,
      pageSize,
      selectedDate,
      selectedStatus
    ) => {
      dispatch(
        viewAllTestDrive(
          token,
          user_id,
          searchInput,
          page,
          pageSize,
          selectedDate,
          selectedStatus
        )
      );
    },

    updateTestDriveStatus: (token, user_id, id, status, page, pageSize) => {
      dispatch(updateTestDriveStatus(token, user_id, id, status, page, pageSize));
    },

    deleteTestDrive: (token, user_id, id, page, pageSize) => {
      dispatch(deleteTestDrive(token, user_id, id, page, pageSize));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
