import {
  ALLADDES,
  ADDESBYSTATUS,
  ALLCONTACTUS,
  ALLTESTDRIVE,
} from "./constant";
import UNIVERSAL from "../../src/Config/Config";
import { setLoader, unsetLoader } from "../Common/loader/action";
import { set_snack_bar } from "../Common/snackbar/action";
import "firebase/storage";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export function uploadImaes(token, user_id, image, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader(true));
    if (image !== "") {
      const storageRef = ref(
        getStorage(),
        "/addes/" + image + Math.random() + ".png"
      );
      const uploadimage = uploadBytesResumable(storageRef, image);
      uploadimage.on(
        "state_changed",
        function (snapshot) {},
        function (error) {
          console.log(error);
          //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
          dispatch(setLoader(false));
        },

        function () {
          getDownloadURL(uploadimage.snapshot.ref).then(function (image) {
            if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
              dispatch(createAddes(token, user_id, image, page, pageSize));
              dispatch(set_snack_bar(true, "Image Uploaded"));
            } else {
              dispatch(set_snack_bar(true, "Image not Uploaded"));
            }
          });
        }
      );
    } else {
      dispatch(createAddes(token, user_id, image, page, pageSize));
    }
  };
}

export function createAddes(token, user_id, image, page, pageSize) {
  console.log(image, page, pageSize, "aaaaaaaaaaaaaaaaaaaaa");

  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        image,
        user_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function viewAlladdes(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  selectedDate,
  selectedTime,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        selectedDate,
        selectedTime,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLADDES,
            payload: responseJson.data.data,
            count: responseJson.data.totalAddess,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLADDES, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function updateStatus(token, user_id, id, status, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function uploadupdatesImages(token, user_id, id, image, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader(true));
    if (image !== "") {
      const storageRef = ref(
        getStorage(),
        "/addes/" + image + Math.random() + ".png"
      );
      const uploadimage = uploadBytesResumable(storageRef, image);
      uploadimage.on(
        "state_changed",
        function (snapshot) {},
        function (error) {
          console.log(error);
          //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
          dispatch(setLoader(false));
        },

        function () {
          getDownloadURL(uploadimage.snapshot.ref).then(function (image) {
            if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
              dispatch(update(token, user_id, id, image, page, pageSize));
              dispatch(set_snack_bar(true, "Image Uploaded"));
            } else {
              dispatch(set_snack_bar(true, "Image not Uploaded"));
            }
          });
        }
      );
    } else {
      dispatch(update(token, user_id, id, image, page, pageSize));
    }
  };
}

export function update(token, user_id, id, image, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        image,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function deleteAddes(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function viewAddesByStatus() {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/viewAddesByStatus", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({ type: ADDESBYSTATUS, payload: responseJson.data.result });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ADDESBYSTATUS, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
//------------------------------------------Test Drive--------------------------------------------------------------------//
export function BookTestDrive(
  name,
  email,
  mobile,
  modelType,
  dateTime,
  address
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "testDrive/bookTestDrive", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        mobile,
        modelType,
        dateTime,
        address,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch();
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch();
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewAllTestDrive(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  selectedDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "testDrive/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        selectedDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLTESTDRIVE,
            payload: responseJson.data.data,
            count: responseJson.data.testDriveDetail,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLTESTDRIVE, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteTestDrive(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "testDrive/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllTestDrive(token, user_id, "", page, pageSize, "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllTestDrive(token, user_id, "", page, pageSize, "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function updateTestDriveStatus(
  token,
  user_id,
  id,
  status,
  page,
  pageSize
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "testDrive/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllTestDrive(token, user_id, "", page, pageSize, "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllTestDrive(token, user_id, "", page, pageSize, "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
//----------------------------------------------ContactUs---------------------------------------------------------//

export function AddContactForm(
  firstName,
  lastName,
  mobile,
  email,
  queryType,
  querySubject,
  query,
  agreedToPolicy
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        mobile,
        email,
        queryType,
        querySubject,
        query,
        agreedToPolicy,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch();
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch();
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewAllContactUs(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  selectedDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        selectedDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLCONTACTUS,
            payload: responseJson.data.data,
            count: responseJson.data.contactUsDetail,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLCONTACTUS, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteContact(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function updateContactStatus(
  token,
  user_id,
  id,
  status,
  page,
  pageSize
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
