import { Component } from 'react';
import React from 'react';
import './zs.css';
import {
  Box,
  Typography,
} from '@mui/material';
import Navbar from '../../Home/Components/Nav';
import Footer from '../../Home/Components/Footer';
import Zscarrousel from './Zscarasole'
import Features from './Features/Features';
import Zscalc from './calculatorhub';
import Carrosuel from './cardcaraousel'
import Slider from '@mui/material/Slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import charge from '../../images/zs/charge.png';
import battery from '../../images/zs/battery.png';
import speed from '../../images/zs/speed.png';
import adas from '../../images/zs/adas.png';
import Contactus from '../../contact/Contactus'
import Pricing from '../../MgZsEv/Components/CityPricing'
import Deets from '../../MgZsEv/Components/deetssection';
import Faq from '../../MgZsEv/Components/zsfaqs'
import calculator from '../../images/zs/calc.svg'
class Comet extends Component {
   
  render() {
    return (
      <div id='container'>
        <Navbar />
        {/* Banner */}
        <div id="banner-image-zs" >
          <div id="hect-left">
            <div id="hl1">
            <img src={charge} alt="logo1"  />
              <p>SINGLE CHARGE<br/>
              461* kms</p>
            </div>
            <div id="hl2">
            <img src={battery} alt="logo2"  />
              <p>BATTERY CAPACITY
              <br/>50.3 kWh</p>
            </div>
          </div>
          <div id="hect-center">
            <div id="hc1" style={{marginTop:'185px'}}>
            </div>
            <div id="zc2">
                <Zscarrousel/>    
            </div>
          </div>
          <div id="hect-right">
            <div id="hr1">
              <img src={speed} alt="logo3"  />
              <p>0-100 kmph<br/>
              8.5 seconds</p>
            </div>
            <div id="hr2">
              <img src={adas} alt="logo4"  />
              <p>ADAS
              <br/>AUTONOMOUS LEVEL 2
              </p>
            </div>
          </div>
        </div>

        {/* Features Section */}
       <Features/>

        {/* All that Deets */}
        <Box
            id="features"
            sx={{
              width: '100%',
              height:'100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: { md: '0', xs: '0' }, 
              marginTop: { md: '20px', xs: '0' },
             
            }}
          >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { md: '2.08333vw', xs: '6vw' }, 
                  color: '#cf2230',
                  fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
                  paddingBottom: { md: '3.125vw', xs: '20px' },
                  fontWeight: '400',
                  textAlign: 'center',
                  marginTop:'10px',
                }}
              >
                All The Deets
              </Typography>
              <Deets />
        </Box>

        
        {/* Cards Carousel Section */}
       <Carrosuel/>

       {/* contact us */}
       <Contactus/>

       {/* calculatorhub */}
                {/* <Zscalc/> */}
       {/* calculator */}
            {/* <Box
              sx={{
                height: { md: '66vh', xs: 'auto' }, // Set auto height for small screens
                width: '100%',
                display: 'flex',
                flexDirection: 'column', 
                justifyContent: 'center',
                alignItems: 'center',
                '@media (min-width: 1024px) and (max-width: 1139px)': {
                    height:'84vh'
                  },
                  '@media (min-width: 1440px) and (max-width: 1439px)': {
                    height:'70vh'
                  },
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: '2.08333vw', xs: '4.4vw' },
                  color: '#cf2230',
                  fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
                  paddingBottom: { xs: '5vw', md: '3.125vw' },
                  fontWeight: '400',
                }}
              >
                EMI CALCULATOR
              </Typography>

              <Box
                sx={{
                  height: { md: '58vh', xs: 'auto' },
                  width: { xs: '95%', md: '87%' },
                  boxShadow: '0px 0px 20px grey',
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' }, 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  '@media (min-width: 1024px) and (max-width: 1139px)': {
                    flexDirection:'column',
                    height:'75vh'
                  },
                }}
                
              >
                <Box
                  sx={{
                    height: { md: '58vh', xs: 'auto' },
                    width: { xs: '100%', md: '100px' },
                    backgroundColor: '#EAE9E7',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: { xs: '10px', md: 0 },
                    '@media (min-width: 1024px) and (max-width: 1139px)': {
                    width:'100%',
                    height:'130px'
                  },
                  }}
                >
                  <img src={calculator} height='90px' width='80px' alt='calculator' />
                </Box>

                <Box
                  sx={{
                    height: { md: '58vh', xs: 'auto' },
                    width: { xs: '100%', md: '880px' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: { xs: '20px 0', md: '0' }, 
                  }}
                >
                  <Box
                    sx={{
                      height: 'auto', 
                      width: { xs: '100%', md: '850px' },
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: { xs: '20px', md: '30px' },
                      '@media (min-width: 1024px) and (max-width: 1139px)': {
                    marginTop:'-60px',
                  },
                    }}
                  >
                    <Box
                      sx={{
                        height: { md: '35vh', xs: 'auto' }, // Let content size naturally on small screens
                        width: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans', marginTop: { xs: '20px', md: 0 } }}>
                        LOAN AMOUNT(₹)
                      </Typography>
                      <Slider defaultValue={10} aria-label="Default" valueLabelDisplay="auto" />
                      <Box sx={{ height: '20px', width: '200px', display: 'flex', color: 'grey', gap: '53px' }}>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>from ₹10K</Typography>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>to ₹13.99L</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        height: { md: '35vh', xs: 'auto' },
                        width: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans' }}>INTEREST RATE (%) P.A</Typography>
                      <Slider defaultValue={10} aria-label="Default" valueLabelDisplay="auto" />
                      <Box sx={{ height: '20px', width: '200px', display: 'flex', color: 'grey', gap: '90px' }}>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>from 7%</Typography>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>to 15%</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        height: { md: '35vh', xs: 'auto' },
                        width: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans' }}>TENURE (YEARS)</Typography>
                      <Slider defaultValue={10} aria-label="Default" valueLabelDisplay="auto" />
                      <Box sx={{ height: '20px', width: '200px', display: 'flex', color: 'grey', gap: '60px' }}>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>from 1 year</Typography>
                        <Typography sx={{ fontFamily: 'Gill Sans' }}>to 8 years</Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      height: '22vh', 
                      width: { xs: '100%', md: '850px' },
                      backgroundColor: '#FAFAFA',
                      display: 'flex',
                      flexDirection: { xs: 'row', md: 'row' }, 
                      justifyContent: 'space-evenly', 
                      alignItems: 'center',
                      padding: { xs: '20px', md: '10px' }, 
                    }}
                  >
                    <Box
                      sx={{
                        height: 'auto',
                        width: '100%', // Full width on small screens
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans', fontSize: { xs: '10px' } }}>LOAN AMOUNT</Typography>
                      <Typography sx={{ fontFamily: 'Roboto-Medium', fontWeight: '700', fontSize: { md: '25px', xs: '15px' } }}>
                        ₹10,000
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        height: 'auto',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans', fontSize: { xs: '10px' } }}>DOWN PAYMENT</Typography>
                      <Typography sx={{ fontFamily: 'Roboto-Medium', fontWeight: '700', fontSize: { md: '25px', xs: '15px' } }}>
                        ₹50,000
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        height: 'auto',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'Gill Sans', fontSize: { xs: '10px' } }}>EMI AMOUNT</Typography>
                      <Typography sx={{ fontFamily: 'Roboto-Medium', fontWeight: '700', fontSize: { md: '25px', xs: '15px' } }}>
                        ₹1,000
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    height: 'auto',
                    width: { xs: '100%', md: '230px' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px',
                    marginTop: { xs: '20px', md: 0 }, // Add margin on smaller screens to separate sections
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'GillSansSemiBold',
                      fontSize: { xs: '4vw', md: '20px' },
                      fontWeight: 'bold',
                    }}
                  >
                    CAR PRICE
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Roboto-Bold',
                      fontSize: { xs: '5vw', md: '24px' },
                      fontWeight: 'bold',
                      marginBottom:{md:'30px',xs:'20px'}
                    }}
                  >
                    ₹18,98,000*
                  </Typography>
                </Box>
              </Box>
            </Box> */}

       {/* faqs */}
          <Faq/>
        {/* Image*/}      
     <div id="zsimg"></div>
        {/* Pricing Section */}
        {/* <Pricing/> */}
        {/* Footer Section */}
        <Footer />
      </div>
    );
  }
}

export default Comet;