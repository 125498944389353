import React, { Component } from "react";
import { Box, Typography, Button, Divider,Select,FormControl,MenuItem } from '@mui/material';
import ext1 from '../../../images/Windsor/ext1.webp';
import ext2 from '../../../images/Windsor/ext2.webp';
import ext3 from '../../../images/Windsor/ext3.webp';
import ext4 from '../../../images/Windsor/ext4.webp';
import int1 from '../../../images/Windsor/int1.webp'; 
import int2 from '../../../images/Windsor/int2.webp';
import int3 from '../../../images/Windsor/int3.webp';
import int4 from '../../../images/Windsor/int4.webp';
import safe1 from '../../../images/Windsor/safe1.webp';
import safe2 from '../../../images/Windsor/safe2.webp';
import safe3 from '../../../images/Windsor/safe3.webp';
import safe4 from '../../../images/Windsor/safe4.webp';
import i1 from '../../../images/Windsor/i1.webp';
import i2 from '../../../images/Windsor/i2.webp';
import i3 from '../../../images/Windsor/i3.webp';
import i4 from '../../../images/Windsor/i4.webp';
import comf1 from '../../../images/Windsor/comfort1.webp';
import comf2 from '../../../images/Windsor/comfort2.webp';
import comf3 from '../../../images/Windsor/comfort3.webp';
import comf4 from '../../../images/Windsor/comfort4.jpg';


class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'EXTERIOR',
      isMobile: window.innerWidth <= 768, // Detect initial mobile view
      selectedImage: this.getTabImages('EXTERIOR')[0], 
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };


  getTabImages = (tab) => {
    const tabData = {
      EXTERIOR: [
        {
          src: ext1,
          label:'AeroGlide Design',
          title: 'AeroGlide Design',
          subtitle: 'A MARVEL ON THE MOVE',
          description: 'A unique form which gives you the spotlight on the roads while giving you the space for grand experiences.',
        },
        {
          src: ext2,
          label: 'R18 Diamond Cut Alloy Wheels',
          title:'R18 Diamond Cut Alloy Wheels',
          subtitle: 'STYLED TO PERFECTION',
          description: 'A unique style that means business and catches everyone’s attention.',
        },
        {
          src: ext3,
          label: 'Smart Flush Door Handles',
          title:'Smart Flush Door Handles',
          subtitle: 'STYLED TO PERFECTION',
          description: 'A unique style that means business and catches everyone’s attention.',
        },
        {
          src: ext4,
          label: 'Illuminated Front MG Logo',
          title:'Illuminated Front MG Logo',
          subtitle: 'STYLED TO PERFECTION',
          description: 'A unique style that means business and catches everyone’s attention.',
        }
      ],
      INTERIOR: [
        {
          src: int1,
          label:'Infinity View Glass Roof',
          title: 'Infinity View Glass Roof',
          subtitle: 'LIVE MILES OF OPULENCE',
          description: 'A new way to look at the world above while basking in all its glory.',
        },
        {
          src: int2,
          label:'Royal Touch Gold Interior Highlights',
          title: "Royal Touch Gold Interior Highlights",
          subtitle: 'LIVE MILES OF OPULENCE',
          description: 'The aura of luxury goes with you on every drive. ',
        },
        {
          src: int3,
          label:'GrandViewTouch Display',
          title: 'GrandViewTouch Display',
          subtitle: 'LIVE MILES OF OPULENCE',
          description: 'Entertainment scales up to a whole new level, giving you a grand experience worth remembering.',
        },
        {
          src: int4,
          label:'Wireless Charging',
          title: 'Wireless Charging',
          subtitle: 'LIVE MILES OF OPULENCE',
          description: 'Conveniently charge your phone while you enjoy the drive.',
        }
      ],
      'COMFORT & CONVENIENCE': [
        {
          src: comf1,
          label:'Aero-Lounge Seats',
          title: 'Aero-Lounge Seats',
          subtitle: 'YOUR ZONE OF COMFORT',
          description: 'First-in-segment Aero-Lounge Seats with 135° Recline. Rediscover the joy of comfort when its boundaries are pushed to a whole new level. Enjoy the calm as you sit back and relax on the cosy reclining seats.',
        },
        {
          src: comf2,
          label:'Largest-In-Segment Boot Space (604 L)',
          title: "Largest-In-Segment Boot Space (604 L)",
          subtitle: 'YOUR ZONE OF COMFORT',
          description: 'A 604 L space ensures that you get the freedom to carry what you need and more. ',
        },
        {
          src: comf3,
          label:'Front Ventilated Seats',
          title: 'Front Ventilated Seats',
          subtitle: 'YOUR ZONE OF COMFORT',
          description: 'Comfort goes to the next level with seats that cool you down or warm you up as per your need.',
        },
        {
          src: comf4,
          label:'256 Colour Ambient Lights',
          title: '256 Colour Ambient Lights',
          subtitle: 'YOUR ZONE OF COMFORT',
          description: 'Easily set the ambience of your drives to reflect your mood.​',
        }
      ],
      "i-SMART": [
        {
          src: i1,
          label:'MG App Store powered by Jio',
          title: 'MG App Store powered by Jio',
          subtitle: 'CONNECT WITH YOUR DRIVES',
          description: 'A store that self-updates and has a variety of applications in categories like OTT and music.',
        },
        {
          src: i2,
          label:'Home-2-Car',
          title: 'Home-2-Car',
          subtitle: 'CONNECT WITH YOUR DRIVES',
          description: 'Your Jio set-top box can be connected to your car to check its charging status, book services, and much more.',
        },
        {
          src: i3,
          label: 'Jio Home screen',
          title:'Jio Home screen',
          subtitle: 'CONNECT WITH YOUR DRIVES',
          description: 'A home screen with all the applications of Jio. ',
        },
        {
          src: i4,
          label: 'Multi-Lingual Voice Support',
          title:'Multi-Lingual Voice Support',
          subtitle: 'CONNECT WITH YOUR DRIVES',
          description: 'Voice commands can be given to control the features of your car in the language of your choice - English, Hindi, Tamil, Telugu, Marathi & Gujarati.',
        }
      ],
      SAFETY: [
        {
          src: safe1,
          label:'6 Airbags (Dual Front, Side & Curtain)',
          subtitle: 'YOUR SAFE SPACE',
          title:'6 Airbags (Dual Front, Side & Curtain)',
          description: 'Dual front airbags, front-seat side-impact airbags, and side-curtain airbags that work together with the seatbelts to give you the feeling of ultimate safety.​',
        },
        {
          src: safe2,
          label:'Safety by Design',
          title: 'Safety by Design',
          subtitle: 'YOUR SAFE SPACE',
          description: 'Drive assured with a structure made of high strength steel to keep you and your family safe.',
        },
        {
          src: safe3,
          label: '360° Surround View Cameras',
          title:'360° Surround View Cameras',
          subtitle: 'YOUR SAFE SPACE',
          description: 'A cutting-edge technology that gives you a view of everything around your car to keep you safe.',
        },
        {
          src: safe4,
          label: 'Electronic Parking Brake with Auto Hold',
          title:'Electronic Parking Brake with Auto Hold',
          subtitle: 'YOUR SAFE SPACE',
          description: 'An Electric Parking Brake System to help you do away with the tediousness of conventional hand brakes.',
        },
      ],
    };
    
    return tabData[tab] || [];
  };

  handleTabChange = (tab) => {
    const images = this.getTabImages(tab);
    this.setState({
      selectedTab: tab,
      selectedImage: images[0], // Default to first image of the selected tab
    });
  };

  handleImageClick = (image) => {
    this.setState({ selectedImage: image });
  };

  renderContent = () => {
    const { selectedTab, selectedImage } = this.state;
    const images = this.getTabImages(selectedTab);
  
    return (
      <Box sx={{ width: '80%', height: '100%',
      }}>
        <Box
          sx={{
            height: '90%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: { xs: 'center', md: 'start' },
            gap: '20px',
            flexWrap: 'no-wrap',
            marginLeft: { xs: '-5px', md: '0px' },
           
          }}
        >
          <img
            src={selectedImage.src}
            style={{
              borderRadius: '10px',
              width: '97vw',
              maxWidth: '500px',
              height: 'auto',
            }}
            alt={selectedImage.title}
          />
  
          <style>
            {`
              @media (max-width: 768px) { /* Tablet and smaller */
                img {
                  width: 80vw; /* Adjust the width for tablet view */
                  max-width: 100%; /* Ensure it doesn't exceed container width */
                }
              }
  
              @media (max-width: 480px) { /* Mobile view */
                img {
                  width: 90vw; /* Increase width for mobile view */
                  max-width: 100%;
                }
              }
            `}
          </style>
  
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { md: 'space-around', xs: 'start', sm: 'start' },
              alignItems: 'start',
              width: '100%',
              maxWidth: '500px',
              marginLeft: { xs: '-25px', md: '0px' }
            }}
          >
            <Typography
              variant="h5"
              sx={{
                width: { xs: '90%', md: '510px' },
                fontFamily: 'GillSansRegular, Arial, sans-serif',
                color: '#000',
                fontSize: { xs: '4vw', md: '1.5625vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.title}
            </Typography>
            <Typography
              sx={{
                width: { xs: '90%', md: '330px', sm: '90%' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                color: '#6e6e6e',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.5vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.subtitle}
            </Typography>
            <Typography
              sx={{
                width: { xs: '100%', md: '460px', lg: '450px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '1.30208vw',
                '@media (min-width: 1024px) and (max-width: 1139px)': {
                  width: '280px',
                },
              }}
            >
              {selectedImage.description}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: { xs: 'center', md: 'flex-start' },
            gap: '10px',
            marginTop: { xs: '10px', md: '50px', lg: '20px' },
            marginLeft: { xs: '-10px', md: '0px' },
          }}
        >
          {images.map((img, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { md: 'center', xs: 'start' }
              }}
            >
              <img
                src={img.src}
                style={{
                  borderRadius: '10px',
                  width: '20vw',
                  maxWidth: '250px',
                  height: 'auto',
                  marginTop: { xs: '10px', md: '40px', sm: '20px' }
                }}
                alt={img.label}
                onClick={() => this.handleImageClick(img)}
              />
              <Typography
                sx={{
                  fontFamily: 'Roboto-Medium, Arial, sans-serif',
                  fontSize: { xs: '3.5vw', md: '1.04167vw' },
                  color: '#000',
                  paddingTop: '1.04167vw',
                  margin: 0,
                  fontWeight: '550',
                  display: { md: 'flex', xs: 'none' },
                  textAlign: { xs: 'none', md: 'left' },
                  width: '100%'
                }}
              >
                {img.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };
  


  render() {
    const { selectedTab, isMobile } = this.state;
    const tabs = ['EXTERIOR', 'INTERIOR',  'COMFORT & CONVENIENCE', 'i-SMART','SAFETY'];

    return (
      <Box
        id="features"
        sx={{
          height: 'auto',
          width: '100%',
          minHeight: '85vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '6vw', md: '2.08333vw' }, 
            color: '#cf2230',
            fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
            paddingBottom: '3.125vw',
            fontWeight: '400',
            marginTop:'20px'
          }}
        >
          A Car That Has It All
        </Typography>

        {isMobile ? (
          <FormControl fullWidth sx={{ width: '80%', marginBottom: '20px', }}>
            <Select
              value={selectedTab}
              style={{fontWeight:'700'}}
              onChange={(e) => this.handleTabChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Tab Selection' }}
            >
              {tabs.map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              height: '15px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            {tabs.map((tab) => (
              <Button
                key={tab}
                onClick={() => this.handleTabChange(tab)}
                sx={{
                  fontFamily: 'GillSansRegular, Arial, sans-serif',
                  fontSize: { xs: '4vw', md: '1.25vw' }, 
                  color: selectedTab === tab ? 'white' : 'black',
                  backgroundColor: selectedTab === tab ? '#2F444D' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#2F444D',
                    color: 'white',
                  },
                }}
              >
                {tab}
              </Button>
            ))}
          </Box>
        )}

        <Divider sx={{ width: '80%', display:{md:'flex',xs:'none'},border: '4px solid #2F444D', margin: '20px 0' }} />

        {this.renderContent()}

        {/* <Button
          sx={{
            backgroundColor: '#CF2230',
            color: 'white',
            fontWeight: '700',
            fontFamily: 'GillSansSemiBold , sans-serif',
            fontSize: { xs: '3.5vw', md: '0.95vw',sm:'2.2vw' }, 
            cursor: 'pointer',
            marginTop:'30px',
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
            },
          }}
        >
          Virtual Showroom
        </Button> */}
      </Box>
    );
  }
}

export default Features;
