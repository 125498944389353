import React, { useState } from 'react';
import { Container, Grid, Box, Button, Typography, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import img1 from '../../../../images/img1.svg';
import img2 from '../../../../images/img2.svg';
import img3 from '../../../../images/img3.svg';
import deetcar from '../../../../images/storm/deetcar.png';

const styles = {
  redBox: {
    backgroundColor: '#EAE9E7',
    height: '800px',
    width: { md: '500px', xs: '350px' }, // Increased the width for mobile screens
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    color: 'white',
    fontSize: { md: '24px', xs: '18px' }, // Font size reduced for smaller screens
    flexDirection: 'column',
  },
  redBoxContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '10px',
  },
  whiteBox: {
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 'bold',
    width: { md: '300px', xs: '80%' }, // Adjust width responsively
    height: '78px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelText: {
    textAlign: 'center',
    color: 'white',
    fontSize: { md: '16px', xs: '12px' }, // Adjust font size responsively
  },
  questionBox: {
    padding: '10px',
    backgroundColor: '#F2F2F2',
    border: '1px solid #ddd',
    borderRadius: '4px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'black',
  },
  expandableContent: {
    padding: '10px',
    backgroundColor: '#fff',
  },
  downloadButton: {
    backgroundColor: '#E32735',
    color: 'white',
    marginTop: '20px',
    marginBottom: '20px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:hover': {
      backgroundColor: 'black',
    },
  },
  listContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  listColumn: {
    flex: 1,
    marginRight: '10px',
  },
  listItem: {
    marginBottom: '10px',
  },
  listPoint: {
    fontWeight: 'bold',
  },
  listValue: {
    marginLeft: '10px',
  },
};

function QuestionBox({ question, expanded, onToggle, dimensionsList, powertrainList }) {
  return (
    <Box 
      sx={styles.questionBox} 
      onClick={onToggle}  
      style={{ cursor: 'pointer' }}  
    >
      <Box sx={styles.questionHeader}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontSize: { md: '18px', xs: '14px' },  // Decrease font size
            fontWeight: 'bold',
          }}
        >
          {question}
        </Typography>
        <Button 
          onClick={onToggle} 
          sx={{ color: 'black', pointerEvents: 'none' }}  
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>
      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} alignItems="center">
              <Box sx={styles.listContainer}>
                <Box sx={styles.listColumn}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '10px' }}>
                    Dimension and Chassis
                  </Typography>
                  <ul>
                    {dimensionsList.map((item, index) => (
                      <li key={index} style={styles.listItem}>
                        <Typography variant="body2" sx={styles.listPoint}>
                          {item}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
                <Box sx={styles.listColumn}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '12px' }}>
                    Powertrain and Performance
                  </Typography>
                  {powertrainList.map((item, index) => (
                    <Typography key={index} style={styles.listItem}>
                      <Typography variant="body2" sx={styles.listValue}>
                        {item}
                      </Typography>
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Button sx={styles.downloadButton}>Download Brochure</Button>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
}

export default function App() {
  const [expanded, setExpanded] = useState(null);

  const dimensionsLists = [
    ['Length x Width x Height (mm)', 'Wheelbase (mm)', 'Wheel and tyre', 'Fuel Tank Capacity (L)', 'Seat capacity', 'Suspension(Front)', 'Suspension(Rear)'],
    ['Length x Width x Height (mm)', 'Wheelbase (mm)', 'Wheel and tyre', 'Fuel Tank Capacity (L)', 'Seat capacity', 'Suspension(Front)', 'Suspension(Rear)'],
    ['Length x Width x Height (mm)', 'Wheelbase (mm)', 'Wheel and tyre', 'Fuel Tank Capacity (L)', 'Seat capacity', 'Suspension(Front)', 'Suspension(Rear)'],
  ];

  const powertrainLists = [
    ['4,985 x 1,926 x 1,867', '2,950', ' 255/55 R19 (19")  : 48.74 cm Alloys', '75', '7', ' Double-Wishbone Suspension', 'Multi Link Double Suspension'],
    ['4,985 x 1,926 x 1,867', '2,950', ' 255/55 R19 (19")  : 48.74 cm Alloys', '75', '7', ' Double-Wishbone Suspension', 'Multi Link Double Suspension'],
    ['4,985 x 1,926 x 1,867', '2,950', ' 255/55 R19 (19")  : 48.74 cm Alloys', '75', '7', ' Double-Wishbone Suspension', 'Multi Link Double Suspension'],
  ];


  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Container>
      <Grid container spacing={0}>
        <Grid item xs={12} md={7}>
          <Typography sx={{ fontWeight: '770', fontSize: {md:'1.25vw',xs:'3.2vw'}, marginBottom: '20px' }}>
            KEY SPECIFICATIONS
          </Typography>
          <Box sx={{ display: 'flex', gap: 0 ,marginLeft:{md:'0',xs:'-20px'}}}>
            <Box sx={styles.redBox}>
              <Box sx={styles.redBoxContent} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Box sx={styles.redBoxContent} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                  <img src={img1} height="60px" />
                  <Box sx={styles.whiteBox}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: {md:'25px',xs:'12px'}, fontFamily: 'Roboto-Bold' }}>8AT</Typography>
                  </Box>
                  <Typography sx={styles.labelText}>TRANSMISSION</Typography>
                </Box>

                <Box sx={styles.redBoxContent} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                  <img src={img2} height="60px" />
                  <Box sx={styles.whiteBox}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: {md:'25px',xs:'12px'}, fontFamily: 'Roboto-Bold' }}>6 & 7</Typography>
                  </Box>
                  <Typography sx={styles.labelText}>SEATING</Typography>
                </Box>

                <Box sx={styles.redBoxContent} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                  <img src={img3} height="60px" />
                  <Box sx={styles.whiteBox}>
                    <Typography sx={{ fontWeight: 'bold', fontSize:  {md:'25px',xs:'12px'}, fontFamily: 'Roboto-Bold' }}>Diesel 2L Turbo and Twin Turbo</Typography>
                  </Box>
                  <Typography sx={styles.labelText}>ENGINE</Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '0px' }}>
              <img src={deetcar} style={{ marginTop: '40px', width: '320px', maxWidth: '600px',marginLeft:'-50px' }} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          <Typography sx={{ textAlign: 'center', fontWeight: '770', fontSize:{md: '1.25vw',xs:'3.2vw'}, marginBottom: '20px' }}>
            TECHNICAL SPECIFICATIONS
          </Typography>
          <Box>
            {['GLOSTER SNOWSTORM', 'GLOSTER DESERTSTORM', 'GLOSTER BLACKSTORM', ].map((question, index) => (
              <QuestionBox
                key={index}
                question={question}
                expanded={expanded === index}
                onToggle={() => handleToggle(index)}
                dimensionsList={dimensionsLists[index]}
                powertrainList={powertrainLists[index]}
              />
            ))}
          </Box>
        </Grid>
      </Grid>

      

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button
          sx={{
            backgroundColor: '#CF2230',
            color: 'white',
            fontWeight: '700',
            cursor:'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'GillSansSemiBold, sans-serif',
            '&:hover': {
              backgroundColor: '#000',
            },
          }}
        >
          View All Specifications
        </Button>
      </Box>
    </Container>
  );
}
