import React, { Component } from 'react';
import {
    Grid, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, IconButton, Tooltip,
    TextField, Button, InputAdornment, Typography, DialogActions, Dialog, DialogTitle, DialogContent, Select, MenuItem
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { Visibility } from '@mui/icons-material';
import { Delete, Edit } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Search as SearchIcon, Block as BlockIcon, } from '@mui/icons-material';
import "./addes.css";
function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}

class Employee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: false,
            add: false,
            edit: false,
            reject: false,
            selectedEmployee: null,
            searchInput: '',
            page: 0,
            rowsPerPage: 10,
            image: null,
            candidates_id: 0,
            selectedData: null,
            addes_id: "",
            selectedDate: '',
            selectedTime: '',
            selectedStatus: ''
        };
        this.debouncedSearch = _.debounce(this.handleSearch, 300);
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => {
            this.props.viewAlladdes(this.props.login.token,this.props.login.user_id,this.state.searchInput, newPage, this.state.rowsPerPage,this.state.selectedDate,this.state.selectedTime,this.state.selectedStatus);
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        }, () => {
            this.props.viewAlladdes(this.props.login.token,this.props.login.user_id,this.state.searchInput, 0, this.state.rowsPerPage,this.state.selectedDate,this.state.selectedTime,this.state.selectedStatus);
        });
    };

    toggleStatus = async (item) => {
        const newStatus = item.status === 'Active' ? 'Inactive' : 'Active';
        this.props.updateStatus(this.props.login.token,this.props.login.user_id,item._id, newStatus, this.state.page, this.state.rowsPerPage)
    };

    handleSearch = () => {
        this.props.viewAlladdes(this.props.login.token,this.props.login.user_id,this.state.searchInput, this.state.page, this.state.rowsPerPage,this.state.selectedDate,this.state.selectedTime,this.state.selectedStatus);
    };

    handleSearchChange = (event) => {
        const searchInput = event.target.value;
        this.setState({ searchInput }, () => {
            this.debouncedSearch();
        });
    };

    handleDialogOpen = (employee) => {
        this.setState({ selectedEmployee: employee, view: true });
    };

    handleDialogClose = () => {
        this.setState({ view: false, selectedEmployee: null });
    };

    componentDidMount() {
        this.props.viewAlladdes(this.props.login.token,this.props.login.user_id,this.state.searchInput, this.state.page, this.state.rowsPerPage,this.state.selectedDate,this.state.selectedTime,this.state.selectedStatus);
    }

    handleDateChange = (event) => {
        this.setState({ selectedDate: event.target.value });
        this.props.viewAlladdes(this.props.login.token,this.props.login.user_id,this.state.searchInput, this.state.page, this.state.rowsPerPage,event.target.value,this.state.selectedTime,this.state.selectedStatus);
        console.log("Selected Date:", event.target.value);
    };

    handleTimeChange = (event) => {
        this.setState({ selectedTime: event.target.value });
        this.props.viewAlladdes(this.props.login.token,this.props.login.user_id,this.state.searchInput, this.state.page, this.state.rowsPerPage,this.state.selectedDate,event.target.value,this.state.selectedStatus);

        console.log("Selected Time:", event.target.value);
    };

    handleStatusChange = (event) => {
        this.setState({ selectedStatus: event.target.value });
        this.props.viewAlladdes(this.props.login.token,this.props.login.user_id,this.state.searchInput, this.state.page, this.state.rowsPerPage,this.state.selectedDate,this.state.selectedTime,event.target.value,);

        console.log("Selected Status:", event.target.value);
    };

    render() {
        const { searchInput, selectedEmployee, view, add, edit, page, rowsPerPage } = this.state;

        return (
            <Grid container spacing={2} style={{alignItems:'center',marginTop:'50px'}}>

                {/* Header Section: Create Advertisement Button */}
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <Button variant="contained" color="primary" onClick={() => this.setState({ add: true })}>
                        Create Advertisement
                    </Button>
                </Grid>

                {/* Filter and Pagination Section */}
                <Grid item xs={12} style={{ marginBottom: '20px',alignItems:'center' }}>
                    <Grid container spacing={2} style={{alignItems:'center'}}>

                        {/* Date Filter */}
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                                label="Filter by Date"
                                type="date"
                                onChange={this.handleDateChange}
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '100%' }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>

                        {/* Time Filter */}
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                                label="Filter by Time"
                                type="time"
                                onChange={this.handleTimeChange}
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '100%' }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>

                        {/* Status Filter */}
                        <Grid item xs={12} sm={4} md={3}>
                            <Select
                                label="Filter by Status"
                                onChange={this.handleStatusChange}
                                style={{ width: '100%' }}
                                variant="outlined"
                                size="small"
                                defaultValue=""
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Inactive">Inactive</MenuItem>
                            </Select>

                        </Grid>

                        {/* Pagination Section */}
                        <Grid item xs={12} sm={12} md={3} style={{ display: 'flex',justifyContent: 'flex-end', alignItems: 'center',overflow: 'hidden' }}>
                            <TablePagination
                                component="div"
                                count={this.props.client?.all_addeslength || 0}
                                page={page}
                                onPageChange={this.handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                style={{ overflow: 'hidden' }}
                            />

                        </Grid>



                    </Grid>
                </Grid>

                {/* Advertisement Table */}
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ borderRadius: '8px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                                    {["Sl No", "Image", "Date", "Time", "Status", "Action"].map((header, index) => (
                                        <TableCell key={index} align="center">
                                            <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                                                {header}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(this.props.client.all_addes) &&
                                    this.props.client.all_addes.map((item, index) => {
                                        const createdAt = new Date(item.createdAt);
                                        const date = createdAt.toLocaleDateString(); // Formats date as MM/DD/YYYY
                                        const time = createdAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Formats time as HH:MM AM/PM

                                        return (
                                            <TableRow
                                                key={index}
                                                style={{
                                                    backgroundColor: index % 2 === 0 ? '#fafafa' : '#ffffff',
                                                    transition: 'background-color 0.3s',
                                                    '&:hover': { backgroundColor: '#f0f0f0' },
                                                }}
                                            >
                                                <TableCell align="center"><Typography variant="body2">{index + 1}</Typography></TableCell>
                                                <TableCell align="center">
                                                    <img
                                                        src={item.image}
                                                        alt="Advertisement"
                                                        style={{ width: '80px', height: '80px', border: '1px solid #ddd', borderRadius: '4px' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center"><Typography variant="body2">{date}</Typography></TableCell>
                                                <TableCell align="center"><Typography variant="body2">{time}</Typography></TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title={item.status === 'Active' ? "Set Inactive" : "Set Active"}>
                                                        <IconButton onClick={() => this.toggleStatus(item)}>
                                                            <div className="toggle-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={item.status === 'Active'}
                                                                    onChange={() => this.toggleStatus(item)}
                                                                />
                                                                <span className="slider round"></span>
                                                            </div>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="View Image">
                                                        <IconButton onClick={() => this.handleDialogOpen(item)}>
                                                            <Visibility color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Edit">
                                                        <IconButton onClick={() => this.setState({ selectedData: item, edit: true })}>
                                                            <Edit color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <IconButton onClick={() => this.setState({ addes_id: item._id, reject: true })}>
                                                            <Delete color="error" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                {selectedEmployee && (
                    <Dialog open={view} onClose={this.handleDialogClose} fullWidth maxWidth="sm">
                        <DialogTitle>Image Details</DialogTitle>
                        <DialogContent dividers>
                            <Grid container justifyContent="center">
                                <img
                                    src={selectedEmployee.image}
                                    alt="Employee"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </Grid>
                            {/* <Grid container justifyContent="space-between" style={{ marginTop: '16px' }}>
                                <Grid item><Typography>Date: {selectedEmployee.date}</Typography></Grid>
                                <Grid item><Typography>Time: {selectedEmployee.time}</Typography></Grid>
                            </Grid> */}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDialogClose} color="primary">Close</Button>
                        </DialogActions>
                    </Dialog>
                )}

                <Dialog
                    open={add}
                    onClose={() => this.setState({ add: false, image: null })}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>Upload an Image</DialogTitle>
                    <DialogContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                <input
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="upload-image"
                                    type="file"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        this.setState({ image: file });
                                    }}
                                />
                                <label htmlFor="upload-image">
                                    <Button variant="contained" color="primary" component="span" fullWidth>
                                        Choose Image
                                    </Button>
                                </label>
                            </Grid>
                            {this.state.image && (
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Selected image: {this.state.image.name}
                                    </Typography>
                                    <img
                                        src={URL.createObjectURL(this.state.image)}
                                        alt="Selected Preview"
                                        style={{
                                            maxWidth: "100%",
                                            height: "auto",
                                            marginTop: "10px",
                                            borderRadius: "8px",
                                            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                                            maxHeight: "300px"
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.props.uploadImaes(this.props.login.token,this.props.login.user_id,this.state.image, this.state.page, this.state.rowsPerPage);
                                this.setState({ add: false, image: null });
                            }}
                            color="primary"
                            variant="contained"
                        >
                            Add
                        </Button>
                        <Button onClick={() => this.setState({ add: false, image: null })} color="secondary" variant="outlined">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.edit}
                    onClose={() => this.setState({ edit: false, image: null })}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>Upload an Image</DialogTitle>
                    <DialogContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                {/* File Input */}
                                <input
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="upload-image"
                                    type="file"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        this.setState({ image: file });
                                    }}
                                />
                                <label htmlFor="upload-image">
                                    <Button variant="contained" color="primary" component="span" fullWidth>
                                        Choose Image
                                    </Button>
                                </label>
                            </Grid>

                            {/* Image Preview Section */}
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {/* Display selected image name or existing image */}
                                    {this.state.image ? this.state.image.name : "Selected image: " + ("This is your Image" || "No image selected")}
                                </Typography>

                                {/* Image Preview */}
                                {this.state.image || this.state.selectedData?.image ? (
                                    <img
                                        src={this.state.image ? URL.createObjectURL(this.state.image) : this.state.selectedData.image}
                                        alt="Selected Preview"
                                        style={{
                                            maxWidth: "100%",
                                            height: "auto",
                                            marginTop: "10px",
                                            borderRadius: "8px",
                                            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                                            maxHeight: "300px"
                                        }}
                                    />
                                ) : null}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                if (this.state.image) {
                                    this.props.uploadupdatesImages(this.props.login.token,this.props.login.user_id,this.state.selectedData._id, this.state.image, this.state.page, this.state.rowsPerPage);
                                }
                                this.setState({ edit: false, image: null });
                            }}
                            color="primary"
                            variant="contained"
                        >
                            Save Changes
                        </Button>
                        <Button
                            onClick={() => this.setState({ edit: false, image: null })}
                            color="secondary"
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.reject}
                    onClose={() => { this.setState({ reject: false, addes_id: "" }) }}
                >
                    <DialogTitle>Confirm Reject</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to Delete the Advertisement?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => {
                            this.props.deleteAddes(this.props.login.token,this.props.login.user_id,this.state.addes_id, this.state.page, this.state.rowsPerPage);
                            this.setState({ reject: false, addes_id: "" });
                        }} color="primary">Yes</Button>
                        <Button onClick={() => this.setState({ reject: false })} color="secondary">No</Button>
                    </DialogActions>
                </Dialog>

            </Grid>


        );
    }
}

export default withParams(Employee);
