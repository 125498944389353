import React, { Component } from "react";
import { Box, Typography, Button, Divider,Select,FormControl,MenuItem } from '@mui/material';
import fet1 from '../../../images/Comet/fet1.webp';
import fet2 from '../../../images/Comet/fet2.webp';
import fet3 from '../../../images/Comet/fet3.webp';
import fet4 from '../../../images/Comet/fet4.webp';
import safety from '../../../images/Comet/safe.webp'; 
import tech from '../../../images/Comet/tech.webp';
import perf from '../../../images/Comet/perf.webp';
import batt from '../../../images/Comet/battery.webp';
import comf from '../../../images/Comet/comf.webp';



class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'FEATURE',
      isMobile: window.innerWidth <= 768, // Detect initial mobile view
      selectedImage: this.getTabImages('FEATURE')[0], 
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };


  getTabImages = (tab) => {
    const tabData = {
      FEATURE: [
        {
          src: fet1,
          label:'Fabric Seat Upholstery',
          title: 'Haute on the inside',
          subtitle: 'Fabric Seat Upholstery',
          description: 'This car will never cramp your style because it has all it takes to make every drive a TLC session. And one look at its plush, luxurious interiors you’ll know it’s ‘gram worthy.',
        },
        {
          src: fet2,
          label:'10.25" Integrated Floating Widescreen',
          title: 'Haute on the inside.',
          subtitle: '10.25" Integrated Floating Widescreen',
          description: 'This car will never cramp your style because it has all it takes to make every drive a TLC session. And one look at its plush, luxurious interiors you’ll know it’s ‘gram worthy.',
        },
        {
          src: fet3,
          label: 'Leather covered Steering Wheel',
          title:'Haute on the inside.',
          subtitle: 'Leather covered Steering Wheel',
          description: 'This car will never cramp your style because it has all it takes to make every drive a TLC session. And one look at its plush, luxurious interiors you’ll know it’s ‘gram worthy.',
        },
        {
          src: fet4,
          label: '2-Door and 4-Seater',
          title:'Haute on the inside.',
          subtitle: '2-Door and 4-Seater',
          description: 'This car will never cramp your style because it has all it takes to make every drive a TLC session. And one look at its plush, luxurious interiors you’ll know it’s ‘gram worthy.',
        }
      ],
      SAFETY: [
        {
          src: safety,
          title: '‘No worries’ is not just a catchphrase.',
          subtitle: 'High strength vehicle body with 17 hot stamping panels',
          description: 'MG Comet EV isn’t all about fun and games. This car cares about you deeply and it shows it too. Check out some of its safety features and you’ll know it’s legit.',
          description2:'High on safety features* like ABS + EBD, Hill Hold Control, Electronic Stability Control, Electronic Parking Brake, Front & Rear Disc Brakes, and more!'
        },
      
      ],

      TECHNOLOGY: [
        {
          src: tech,
          title: 'Tech OP in the car',
          subtitle: 'Wireless Android Auto & Apple Carplay',
          description: 'MG Comet EV is savvy enough to keep up with your daily needs on every drive. And with its range of cool connected features, you can get your car to do exactly what you want minus the hassles.',
          description2:'Loaded with tech features* like iSmart with 55+ Car connected features, Wireless Android Auto and Apple CarPlay, Floating Twin Display with 10.25” Touchscreen Infotainment System, and more!'
        },
      
      ],
      COMFORT: [
        {
          src: comf,
          title: 'Level of Comfort – Very High',
          subtitle: 'Steering mounted audio controls',
          description: 'Isn’t it sweet when someone takes care of all your needs? That’s exactly what MG Comet EV does with its unique features that make your life a little easier.',
        },
      
      ],
      BATTERY: [
        {
          src: batt,
          title: 'Powering your drive',
          subtitle: 'IP67 Rated Battery',
          description: 'With the 17.3 kWh Prismatic Li-ion battery, MG Comet EV powers your drive for the long run. So be it those long drives in the rain or everyday hustle to your office, you are always sorted.',
        },
      
      ],
      PERFORMANCE:[
        {
          src: perf,
          title: 'Range that’s perfect for the city and you!',
          description: 'The MG Comet EV needs just one charge to travel 230 kms. Which means, the car can take you all around the town several times over, without a break.',
        }
      ]
      
    };
    
    return tabData[tab] || [];
  };

  handleTabChange = (tab) => {
    const images = this.getTabImages(tab);
    this.setState({
      selectedTab: tab,
      selectedImage: images[0], // Default to first image of the selected tab
    });
  };

  handleImageClick = (image) => {
    this.setState({ selectedImage: image });
  };

  renderContent = () => {
    const { selectedTab, selectedImage } = this.state;
    const images = this.getTabImages(selectedTab);
  
    return (
      <Box sx={{ width: '80%', height:'100%',
      }}>
        <Box
          sx={{
            height: '90%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: { xs: 'center', md: 'start' },
            gap: '20px',
            flexWrap: 'no-wrap',
            marginLeft: { xs: '-5px', md: '0px' },
           
          }}
        >
          <img
            src={selectedImage.src}
            style={{
              borderRadius: '10px',
              width: '97vw',
              maxWidth: '500px',
              height: 'auto',
            }}
            alt={selectedImage.title}
          />
  
          <style>
            {`
              @media (max-width: 768px) { /* Tablet and smaller */
                img {
                  width: 80vw; /* Adjust the width for tablet view */
                  max-width: 100%; /* Ensure it doesn't exceed container width */
                }
              }
  
              @media (max-width: 480px) { /* Mobile view */
                img {
                  width: 90vw; /* Increase width for mobile view */
                  max-width: 100%;
                }
              }
            `}
          </style>
  
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { md: 'space-around', xs: 'start', sm: 'start' },
              alignItems: 'start',
              width: '100%',
              maxWidth: '500px',
              marginLeft: { xs: '-25px', md: '0px' }
            }}
          >
            <Typography
              variant="h5"
              sx={{
                width: { xs: '90%', md: '510px' },
                fontFamily: 'GillSansRegular, Arial, sans-serif',
                color: '#000',
                fontSize: { xs: '4vw', md: '1.5625vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.title}
            </Typography>
            <Typography
              sx={{
                width: { xs: '90%', md: '330px', sm: '90%' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                color: '#6e6e6e',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.5vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.subtitle}
            </Typography>
            <Typography
              sx={{
                width: { xs: '100%', md: '460px', lg: '450px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '1.30208vw',
                '@media (min-width: 1024px) and (max-width: 1139px)': {
                  width: '280px',
                },
              }}
            >
              {selectedImage.description}
            </Typography>
            <Typography
              sx={{
                width: { xs: '100%', md: '460px', lg: '450px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '1.30208vw',
                '@media (min-width: 1024px) and (max-width: 1139px)': {
                  width: '280px',
                },
              }}
            >
              {selectedImage.description2}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: { xs: 'center', md: 'flex-start' },
            gap: '10px',
            marginTop: { xs: '10px', md: '50px', lg: '20px' },
            marginLeft: { xs: '-10px', md: '0px' },
          }}
        >
          {images.map((img, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { md: 'center', xs: 'start' }
              }}
            >
              <img
                src={img.src}
                style={{
                  borderRadius: '10px',
                  width: '20vw',
                  maxWidth: '250px',
                  height: 'auto',
                  marginTop: { xs: '10px', md: '40px', sm: '20px' }
                }}
                alt={img.label}
                onClick={() => this.handleImageClick(img)}
              />
              <Typography
                sx={{
                  fontFamily: 'Roboto-Medium, Arial, sans-serif',
                  fontSize: { xs: '3.5vw', md: '1.04167vw' },
                  color: '#000',
                  paddingTop: '1.04167vw',
                  margin: 0,
                  fontWeight: '550',
                  display: { md: 'flex', xs: 'none' },
                  textAlign: { xs: 'none', md: 'left' },
                  width: '100%'
                }}
              >
                {img.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };
  


  render() {
    const { selectedTab, isMobile } = this.state;
    const tabs = ['FEATURE','SAFETY', 'TECHNOLOGY', 'COMFORT',  'BATTERY', 'PERFORMANCE'];

    return (
      <Box
        id="features"
        sx={{
          height: 'auto',
          width: '100%',
          minHeight: '85vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '6vw', md: '2.08333vw' }, 
            color: '#cf2230',
            fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
            paddingBottom: '3.125vw',
            fontWeight: '400',
            marginTop:'20px'
          }}
        >
          A Car That Has It All
        </Typography>

        {isMobile ? (
          <FormControl fullWidth sx={{ width: '80%', marginBottom: '20px', }}>
            <Select
              value={selectedTab}
              style={{fontWeight:'700'}}
              onChange={(e) => this.handleTabChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Tab Selection' }}
            >
              {tabs.map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              height: '15px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            {tabs.map((tab) => (
              <Button
                key={tab}
                onClick={() => this.handleTabChange(tab)}
                sx={{
                  fontFamily: 'GillSansRegular, Arial, sans-serif',
                  fontSize: { xs: '4vw', md: '1.25vw' }, 
                  color: 'black',
                  fontWeight:selectedTab === tab ? 'bolder' : '300',
                  backgroundColor: selectedTab === tab ? '#B9E170' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#B9E170',
                    fontWeight:'bolder',
                  },
                }}
              >
                {tab}
              </Button>
            ))}
          </Box>
        )}

        <Divider sx={{ width: '80%', display:{md:'flex',xs:'none'},border: '4px solid #B9E170', margin: '20px 0' }} />

        {this.renderContent()}

        {/* <Button
          sx={{
            backgroundColor: '#CF2230',
            color: 'white',
            fontWeight: '700',
            fontFamily: 'GillSansSemiBold , sans-serif',
            fontSize: { xs: '3.5vw', md: '0.95vw',sm:'2.2vw' }, 
            cursor: 'pointer',
            marginTop:'30px',
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
            },
          }}
        >
          Virtual Showroom
        </Button> */}
      </Box>
    );
  }
}

export default Features;
