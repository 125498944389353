import { React, Component } from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  Divider,
  InputBase,
  IconButton
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import googleplay from '../../images/googleplay.png'
import appstore from '../../images/app.png'

class Footer extends Component {
  render() {
    return  (
      <>
        {/* Footer */}
        <Box
  sx={{
    width: '100%',
    minHeight: {md:'300px', xs:'500px'}, 
    backgroundColor: '#000000',
    color: 'white',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { xs: 2},
  }}
>
  {/* Left Section */}
  <Box
    sx={{
      marginTop: '20px', 
      marginLeft: '15px',
      maxWidth: { xs: '100%', md: '200px' },
      display: 'flex',
      flexDirection: 'column',
      gap: '10px', 
      textAlign: { xs: 'center', md: 'left' },
      mb: { xs: 2, md: 0 },
    }}
  >

    <Box sx={{ display: 'flex', alignItems: 'center', marginTop:'80px' }}>
      <input type="checkbox" id="check" name="check" value="check" style={{height:'20px'}}/>
      <label htmlFor="check" style={{ fontSize: '10px' }}>
        I agree to the{' '}
        <a style={{ color: 'white' }}>
          <u>privacy policy</u>
        </a>
      </label>
      <br />
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography fontSize="11px" lineHeight="3px" sx={{ mt: 2 }}>
        Call us at 1800-100-6464
      </Typography>
      <Typography fontSize="12px" sx={{ mt: 2 }}>
        Mail us at support@mgmotor.co.in
      </Typography>
    </Box>
  </Box>
  <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />

  {/* Right side */}
  <Box
  sx={{
    marginTop: '20px', 
    width: '100%',
    minHeight: '200px', 
    backgroundColor: '#000000',
    color: 'white',
    p: 2,
  }}
>
  <Grid container spacing={2} wrap="nowrap" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
    <Grid item xs={12} md={1.71}>
      <Typography variant="h6" sx={{ fontSize: '12px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="500">BRAND</Typography><br/>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems:'start',gap: '8px', marginTop: '10px' }}>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>History</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Innovation</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>About MG</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Pillars</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Culture at MG</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Stories of MG</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Safety Fast</a>
      </Box>
    </Grid>
    <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />

    <Grid item xs={12} md={1.71}>
      <Typography variant="h6" sx={{ fontSize: '12px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="300">WORLD OF MG</Typography><br/>
      <Box sx={{ display: 'flex', flexDirection: 'column',alignItems:'start', gap: 1, marginTop: '10px' }}>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>What's New</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Events</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Campaigns</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>i-Smart</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG V Phy</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Stories of MG</a>
      </Box>
    </Grid>
    <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />

    <Grid item xs={12} md={1.71}>
      <Typography variant="h6" sx={{ fontSize: '12px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="300">HELP</Typography><br/>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems:'start',gap: 1, marginTop: '10px' }}>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Dealership</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Contact Us</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Store Locator</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Careers</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>FAQ</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Global Footprint</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Astor Delivery Status</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>EHS Policy</a>
      </Box>
    </Grid>
    <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />

    <Grid item xs={12} md={1.71}>
      <Typography variant="h6" sx={{ fontSize: '12px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="300">MEDIA</Typography><br/>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'start', marginTop: '10px' }}>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Dealership</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Contact Us</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Store Locator</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Careers</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>FAQ</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Global Footprint</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Astor Delivery Status</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>EHS Policy</a>
      </Box>
    </Grid>
    <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />

    <Grid item xs={12} md={1.71}>
      <Typography variant="h6" sx={{ fontSize: '12px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="300">VEHICLES</Typography><br/>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'start', marginTop: '10px' }}>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Astor</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Hector</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Hector Plus(6S)</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Hector Plus(7S)</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG ZS EV</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Gloster</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Comet EV</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Windsor EV</a>
      </Box>
    </Grid>
    <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />

    <Grid item xs={12} md={1.71}>
      <Typography variant="h6" sx={{ fontSize: '12px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="300">PRICE</Typography><br/>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'start', marginTop: '10px' }}>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Astor Price</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Hector Price</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Hector Plus(6S) Price</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Hector Plus(7S) Price</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>ZS EV Price</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Gloster Price</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Comet EV Price</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Windsor EV</a>
      </Box>
    </Grid>
    <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />
    <Grid item xs={12} md={1.71}>
      <Typography variant="h6" fontSize= '1.125rem' textAlign='start' fontFamily= 'RobotoMedium,sans-serif'sx={{ fontSize: '12px' }} fontWeight="300">ADDITIONAL</Typography><br/>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'start', marginTop: '10px' }}>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Visit MG Global</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Car Club</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Download my MG App</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Privacy Policy</a>
      </Box>
    </Grid>
  </Grid>
</Box>
    
</Box>

 {/* Icon part */}
 <Box
  sx={{
    backgroundColor: '#000000',
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    flexDirection: { xs: 'row', md: 'row' }, 
    alignItems: 'center',
    justifyContent: 'end', 
    width: '100%',
    p: 2, 
    gap:'10px'
  }}
>

  
  
    <IconButton sx={{ color: 'grey', border: '1px solid grey' }}>
      <FacebookIcon />
    </IconButton>
    <IconButton sx={{ color: 'grey', border: '1px solid grey' }}>
      <InstagramIcon />
    </IconButton>
    <IconButton sx={{ color: 'grey', border: '1px solid grey' }}>
      <WhatsAppIcon />
    </IconButton>
    <IconButton sx={{ color: 'grey', border: '1px solid grey' }}>
      <YouTubeIcon />
    </IconButton>
    <IconButton sx={{ color: 'grey', border: '1px solid grey' }}>
      <XIcon />
    </IconButton>
  </Box>

       

      </>
    );
  }
}

export default Footer;
