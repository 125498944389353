import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Grid, MenuItem } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import car1 from "../../images/hc-img-dsc-0817.png";
import car2 from "../../images/gs-img-dsc-056.png";
import car3 from "../../images/cm-img-dsc-0180.png";
import car4 from "../../images/as-img-dsc-289.png";
import backgroundImage from "../../images/back.jpg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "@mui/material/styles"; // Import MUI theme hook
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Divider,
  Menu,
  Collapse,
  Select,
  InputLabel,
  Dialog,
  FormControl,
  DialogContent,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ResponsiveCarousel = ({ autoSlide, autoSlideInterval, props }) => {
  const theme = useTheme(); // Use MUI theme for breakpoints
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= 768 && window.innerWidth < 1024
  );
  const [isDesktop1024, setIsDesktop1024] = useState(
    window.innerWidth >= 1024 && window.innerWidth < 1440
  );
  const [isLargeDesktop, setIsLargeDesktop] = useState(
    window.innerWidth >= 1440
  );
  const [openTestDrive, setOpenTestDrive] = useState(false);
  const [address, setAddress] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [modelType, setModelType] = useState();
  const [dateTime, setDateTime] = useState(null);
  const [emailError, setEmailError] = useState();
  const [mobileError, setMobileError] = useState();

  const carData = [
    {
      id: 1,
      image: car1,
      smallhead: "ITs A HUMAN THING",
      headings: "MG HECTOR",
      price: "₹ 18.98 LAKH",
      features: "50.3 kWh Battery",
      testDriveLink: "Hector",
      modelType: "Hector",
    },
    {
      id: 2,
      image: car2,
      smallhead: "DRIVE UNSTOPPABLE",
      headings: "MG GLOSTER",
      price: "₹ 38.80 LAKH",
      features: "4X4, 7 Terrain",
      testDriveLink: "Gloster",
      modelType: "Gloster",
    },
    {
      id: 3,
      image: car3,
      price: "₹ 6.99 LAKH",
      smallhead: "THE STREET SMART CAR",
      headings: "MG COMET EV",
      features: "Floating Twin Display",
      testDriveLink: "Comet",
      modelType: "Comet Ev",
    },
    {
      id: 4,
      image: car4,
      price: "₹ 9.98 LAKH",
      smallhead: "THE MOST ADVANCED SUV",
      headings: "MG ASTOR",
      features: "ADAS L2",
      testDriveLink: "Astor",
      modelType: "Astor",
    },
  ];

  const handleTestDriveClick = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1024);
      setIsDesktop1024(window.innerWidth >= 1024 && window.innerWidth < 1440);
      setIsLargeDesktop(window.innerWidth >= 1440);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobile = (mobile) => {
    const mobileRegex = /^\+?\d{10,14}$/;
    return mobileRegex.test(mobile);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let valid = true;

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!validateMobile(mobile)) {
      setMobileError("Please enter a valid mobile number");
      valid = false;
    } else {
      setMobileError("");
    }

    if (valid) {
      props.BookTestDrive(name, email, mobile, modelType, dateTime, address);
      // Reset form after successful submission
      setName("");
      setEmail("");
      setMobile("");
      setModelType("");
      setDateTime("");
      setAddress("");
    }
  };
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: { xs: "600px", md: "700px", lg: "700px" },
          overflow: "hidden",
          position: "relative",
          margin: 0,
          padding: 0,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Carousel
          showArrows={true}
          showThumbs={false}
          autoPlay={autoSlide}
          interval={autoSlideInterval || 3000}
          infiniteLoop={true}
          transitionTime={500}
          renderArrowPrev={(onClickHandler, hasPrev) =>
            hasPrev && (
              <Button
                onClick={onClickHandler}
                sx={{
                  position: "absolute",
                  left: isMobile ? "-15px" : "40px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 10,
                  color: "#fff",
                  background: "transparent",
                  border: "none",
                  padding: "0",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "#ffeb3b",
                  },
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: 30, color: "#fff" }} />
              </Button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext) =>
            hasNext && (
              <Button
                onClick={onClickHandler}
                sx={{
                  position: "absolute",
                  right: isMobile ? "-15px" : "40px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 10,
                  color: "#fff",
                  background: "transparent",
                  border: "none",
                  padding: "0",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "#ffeb3b",
                  },
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: 30, color: "#fff" }} />
              </Button>
            )
          }
        >
          {carData.map((car) => (
            <div
              key={car.id}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: isTablet ? "10px" : "20px",
                position: "relative",
                marginTop: isMobile
                  ? "100px"
                  : isTablet
                  ? "50px"
                  : isDesktop1024
                  ? "100px"
                  : isLargeDesktop
                  ? "10px"
                  : "50px",
              }}
            >
              <img
                src={car.image}
                alt={`Car ${car.id}`}
                style={{
                  width: isMobile
                    ? "80%"
                    : isTablet
                    ? "60%"
                    : isDesktop1024
                    ? "50%"
                    : isLargeDesktop
                    ? "40%"
                    : "60%",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <div
                style={{
                  backgroundColor: "rgba(0,0,0,0.7)",
                  color: "white",
                  padding: isMobile ? "5px" : "10px",
                  borderRadius: "10px",
                  textAlign: "center",
                  width: isMobile
                    ? "100%"
                    : isTablet
                    ? "60%"
                    : isDesktop1024
                    ? "40%"
                    : isLargeDesktop
                    ? "30%"
                    : "60%",
                  marginTop: isMobile ? "15px" : "1px",
                }}
              >
                <p style={{ fontSize: "14px", margin: 0, wordSpacing: "5px" }}>
                  {car.smallhead}
                </p>
                <h3
                  style={{
                    fontSize: "20px",
                    margin: "5px 0",
                    fontWeight: "700",
                  }}
                >
                  {car.headings}
                </h3>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: { md: "30px", xs: "18px" },
                  }}
                >
                  <p>
                    <span style={{ fontSize: "12px" }}>STARTING AT</span>
                    <br />
                    <b style={{ fontSize: isMobile ? "12px" : "17px" }}>
                      {car.price}
                    </b>
                  </p>
                  <p>
                    <span style={{ fontSize: "12px" }}>FEATURES</span>
                    <br />
                    <b style={{ fontSize: isMobile ? "12px" : "17px" }}>
                      {car.features}
                    </b>
                  </p>
                </Box>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    justifyContent: "center",
                  }}
                >
                  <Link to={car.testDriveLink}>
                    <Button
                      variant="outlined"
                      style={{ color: "#fff", borderColor: "#fff" }}
                    >
                      EXPLORE
                    </Button>
                  </Link>

                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#794F36",
                      color: "white",
                      fontSize: isMobile ? "12px" : "15px",
                    }}
                    onClick={() => {
                      setModelType(car.modelType);
                      setOpenTestDrive(true);
                    }}
                  >
                    TEST DRIVE
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </Box>

      <Dialog
        open={openTestDrive}
        onClose={() => setOpenTestDrive(false)}
        maxWidth="md"
        fullWidth
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(5px)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "800px",
            }}
          >
            <Typography
              sx={{
                color: "#D62E31",
                textAlign: "center",
                fontSize: { md: "40px", xs: "20px", sm: "35px" },
                fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                fontWeight: "bolder",
              }}
            >
              Book a Test Drive
            </Typography>
          </Box>
          <Box
            sx={{
              width: "50px",
            }}
          >
            <CloseIcon onClick={() => setOpenTestDrive(false)} />
          </Box>
        </Box>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  Please fill in the mandatory details for booking a Test Drive.
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Name"
                    name="name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Email"
                    name="email"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    error={!!emailError}
                    helperText={emailError}
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Mobile"
                    name="Mobile"
                    type="number"
                    fullWidth
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    required
                    error={!!mobileError}
                    helperText={mobileError}
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Model Type"
                    name="Model Type"
                    type="text"
                    fullWidth
                    value={modelType}
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="dense" required>
                    <InputLabel
                      sx={{ "& .MuiInputLabel-asterisk": { color: "red" } }}
                    >
                      Model Type
                    </InputLabel>
                    <Select
                      name="modelType"
                      value={modelType}
                      onChange={(e) => setModelType(e.target.value)}
                    >
                      <MenuItem value="Hector">MG Hector</MenuItem>
                      <MenuItem value="Astor">MG Astor</MenuItem>
                      <MenuItem value="Gloster">MG Gloster</MenuItem>
                      <MenuItem value="ZsEv">MG ZS EV</MenuItem>
                      <MenuItem value="CometEv">MG Comet EV</MenuItem>
                      <MenuItem value="Windsor">MG Windsor</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Test Drive Date & Time"
                    name="date"
                    type="datetime-local"
                    fullWidth
                    value={dateTime}
                    onChange={(e) => setDateTime(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Address"
                    name="address"
                    type="text"
                    fullWidth
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                    }}
                    required
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ backgroundColor: "#D23132", fontWeight: "bolder" }}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResponsiveCarousel;
