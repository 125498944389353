import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../Containers/Controller_Cont.js";
import Drawer from "../Containers/DrawerCont.js";
import Login from "../../Auth/Containers/Login_Cont.js";
import Profile from "../../Pages/Profile/Containers/ProfileCont.js";
import Home from "../../Pages/Home/Container/home.js";
import Hector from "../../Pages/MgHector/Components/Hector/Component/Hector.js";
import Hector6seater from "../../Pages/MgHector/Components/Hector6seater/Component/Hector.js";
import Hector7seater from "../../Pages/MgHector/Components/Hector7seater/Components/Hector.js";
import Hectorblackstorm from "../../Pages/MgHector/Components/Hector Blackstorm/Components/Hectorblackstorm.js";
import Gloster from "../../Pages/MgGloster/Components/Gloster/Component/Gloster.js";
import Glosterblackstorm from "../../Pages/MgGloster/Components/Gloster Blackstorm/Components/Glosterblackstorm.js";
import Glosterseries from "../../Pages/MgGloster/Components/Gloster storm Series/Components/Glosterseries.js";
import Astor from "../../Pages/MgAstor/Components/Astor.js";
import Comet from "../../Pages/MgComet/Components/Comet.js";
import ZsEv from "../../Pages/MgZsEv/Components/zs.js";
import Windsor from "../../Pages/MgWindsor/Components/Windsor.js";
import Car360 from "../../Pages/Home/Components/car360d.js";
import NavBar from "../../Pages/Home/Container/Nav.js";
import Clientnav from "../../Client/Containers/NavbarCont.js";
import Client from "../../Client/Containers/advertisementCon.js";
import Contact from "../../Pages/ContactUs/Container/ContactUsCon.js";
import About from '../../Pages/AboutUs/Components/About.js'
import Headers from "../../Pages/Home/Components/headers.js";
import ContactDetails from "../../Client/Containers/ContactDetailsCon.js";
import TestDrive from "../../Client/Containers/testDriveCon.js";

class Routers extends Component {
  render() {
    const { login } = this.props;
    let body;
    console.log(login.type);

    if (login.isHome) {
      body = (
        <div>
          <main style={{ flexGrow: 1 }}>
            <Controller />
            <Routes>
              <Route exact path="/client" element={<Login />} />
              <Route path="/" element={<><Headers /><NavBar /><Home /></>} />
              <Route exact path="/Hector" element={<><Headers /><Hector /></>} />
              <Route exact path="/Hector6seater" element={<><Headers /><Hector6seater /></>} />
              <Route exact path="/Hector7seater" element={<><Headers /><Hector7seater /></>} />
              <Route
                exact
                path="/Hectorblackstorm"
                element={<><Headers /><Hectorblackstorm /></>}
              />
              <Route exact path="/Gloster" element={<><Headers /><Gloster /></>} />
              <Route
                exact
                path="/Glosterblackstorm"
                element={<><Headers /><Glosterblackstorm /></>}
              />
              <Route exact path="/Glosterstorm" element={<><Headers /><Glosterseries /></>} />
              <Route exact path="/Astor" element={<><Headers /><Astor /></>} />
              <Route exact path="/Comet" element={<><Headers /><Comet /></>} />
              <Route exact path="/contact" element={<><Headers /><Contact /></>} />
              <Route exact path="/ZsEv" element={<><Headers /><ZsEv /></>} />
              <Route exact path="/Windsor" element={<><Headers /><Windsor /></>} />
              <Route exact path="/car" element={<><Headers /><Car360 /></>} />
              <Route exact path="/about" element={<><Headers/><About /></>} />
            </Routes>
          </main>
        </div>
      );
    } else {
      switch (login.type) {
        case "SA":
          console.log(login.type);
          body = (
            <div>
              {/* <Drawer type={login.type} /> */}
              <main style={{ flexGrow: 1, paddingTop: 0 }}>
                <Controller />
                <Routes>
                  <Route
                    exact
                    path="/client"
                    element={
                      <>
                        <Clientnav /> <Client />
                      </>
                    }
                  />
                   <Route
                    exact
                    path="/contact-details"
                    element={
                      <>
                        <Clientnav /> <ContactDetails />
                      </>
                    }
                  />
                   <Route
                    exact
                    path="/book-test-drive"
                    element={
                      <>
                        <Clientnav /> <TestDrive />
                      </>
                    }
                  />
                  <Route exact path="/Profile" element={<Profile />} />

                  <Route exact path="/contact" element={<Contact />} />
                  <Route path="/" element={<><Headers /><NavBar /><Home /></>} />
                  <Route exact path="/Hector" element={<><Headers /><Hector /></>} />
                  <Route
                    exact
                    path="/Hector6seater"
                    element={<><Headers /><Hector6seater /></>}
                  />
                  <Route
                    exact
                    path="/Hector7seater"
                    element={<><Headers /><Hector7seater /></>}
                  />
                  <Route
                    exact
                    path="/Hectorblackstorm"
                    element={<><Headers /><Hectorblackstorm /></>}
                  />
                  <Route exact path="/Gloster" element={<><Headers /><Gloster /></>} />
                  <Route
                    exact
                    path="/Glosterblackstorm"
                    element={<><Headers /><Glosterblackstorm /></>}
                  />
                  <Route
                    exact
                    path="/Glosterstorm"
                    element={<><Headers /><Glosterseries /></>}
                  />
                  <Route exact path="/Astor" element={<><Headers /><Astor /></>} />
                  <Route exact path="/Comet" element={<><Headers /><Comet /></>} />
                  <Route exact path="/ZsEv" element={<><Headers /><ZsEv /></>} />
                  <Route exact path="/Windsor" element={<><Headers /><Windsor /></>} />
                  <Route exact path="/car" element={<><Headers /><Car360 /></>} />
                </Routes>
              </main>
            </div>
          );
          break;
        case "E":
          body = (
            <div>
              <Drawer type={login.type} />
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes>
                  <Route exact path="/Profile" element={<Profile />} />
                </Routes>
              </main>
            </div>
          );
          break;
        default:
          body = (
            <div>
              <Drawer type={login.type} />
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes>
                  <Route exact path="/client" element={<Login />} />
                </Routes>
              </main>
            </div>
          );
          break;
      }
    }

    return <Router>{body}</Router>;
  }
}

export default Routers;
