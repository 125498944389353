import React, { Component } from "react";
import { connect } from "react-redux";
import Advertisement from "../Components/advertisement";

import {
    viewAlladdes,
    uploadImaes,
    updateStatus,
    uploadupdatesImages,
    deleteAddes
} from "../action";
export class Controller extends Component {
    render() {
        return (
            <Advertisement {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        client: store.client,
    };
};
export const mapDispatchToProps = dispatch => {
    return {

        viewAlladdes: (token,user_id,searchInput, page, pageSize,selectedDate,selectedTime,selectedStatus) => {
            dispatch(viewAlladdes(token,user_id,searchInput, page, pageSize,selectedDate,selectedTime,selectedStatus));
        },

        uploadImaes: (token,user_id,image, page, pageSize) => {
            dispatch(uploadImaes(token,user_id,image, page, pageSize));
        },

        updateStatus: (token,user_id,id, status,page,pageSize) => {
            dispatch(updateStatus(token,user_id,id, status,page,pageSize));
        },
        uploadupdatesImages: (token,user_id,id, image,page,pageSize) => {
            dispatch(uploadupdatesImages(token,user_id,id, image,page,pageSize));
        },
        deleteAddes: (token,user_id,id,page,pageSize) => {
            dispatch(deleteAddes(token,user_id,id,page,pageSize));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
