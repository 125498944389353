import { Component } from 'react';
import React from 'react';
import './Hectorblackstorm.css';
import {
  Box,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Navbar from '../../../../Home/Components/Nav';
import Footer from '../../../../Home/Components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Contact from '../../../../contact/Contactus'
class Hector extends Component {
   
  render() {
    return (
      <div id='container'>
        <Navbar />
        {/* Banner */}
        <div id="banner-image-hecblack">
  <div id="hecblack-left">
    <Typography
      variant="h4"
      sx={{
        color: 'white',
        marginTop: '120px',
        fontSize: '16px',
        fontFamily: 'GillSansBold, sans-serif',
        fontWeight: '700',
      }}
    >
      HECTOR RANGE PRICE STARTS <br/>
      @ ₹13.99* LAKH
    </Typography>
    <div className="hecblack">
      <button>Download Brochure</button>
      <button>Book A Test Drive</button>
    </div>
    <div className="hecblack">
    <button>Book Now</button>
    <button>360° Experience</button>
    </div>
  </div>
</div>

<div style={{height:'40px',width:'100%',marginTop:'8px',marginLeft:'50px'}}>
<Link to="/" style={{ textDecoration: 'none', color: 'grey',cursor:'pointer' }}>
  <b>Home</b> 
</Link>
<Link to="/Hectorblackstorm" style={{ textDecoration: 'none', color: 'grey',cursor:'pointer' }}>
  &nbsp;&nbsp;MG Hector BlackStorm
</Link>
</div>

{/* Contact */}
<Contact/>
<div id="hecpart2"></div>
<div id="hecpart3"></div>
<div id="hecpart4"></div>
<div id="hecpart5"></div>


        <Footer />
      </div>
    );
  }
}

export default Hector;
