import React, { Component } from "react";
import { Box, Typography, Button, Divider,Select,FormControl,MenuItem } from '@mui/material';
import ext1 from '../../../images/Astor/ext1.jpg';
import ext2 from '../../../images/Astor/ext2.webp';
import ext3 from '../../../images/Astor/ext3.webp';
import ext4 from '../../../images/Astor/ext4.webp';
import int1 from '../../../images/Astor/int1.webp'; 
import int2 from '../../../images/Astor/int2.webp';
import int3 from '../../../images/Astor/int3.webp';
import int4 from '../../../images/Astor/int4.webp';
import safe1 from '../../../images/Astor/safe1.webp';
import safe2 from '../../../images/Astor/safe2.webp';
import safe3 from '../../../images/Astor/safe3.webp';
import adas1 from '../../../images/Astor/adas1.webp';
import adas2 from '../../../images/Astor/adas2.webp';
import adas3 from '../../../images/Astor/adas3.webp';
import adas4 from '../../../images/Astor/adas4.webp';
import i1 from '../../../images/i1.webp';
import i2 from '../../../images/i2.webp';
import i3 from '../../../images/i3.webp';
import i4 from '../../../images/i4.webp';
import comf1 from '../../../images/Astor/comfort1.webp';
import comf2 from '../../../images/Astor/comfort2.webp';
import comf3 from '../../../images/Astor/comfort3.webp';
import comf4 from '../../../images/Astor/comfort4.webp';


class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'ADAS',
      isMobile: window.innerWidth <= 768, // Detect initial mobile view
      selectedImage: this.getTabImages('ADAS')[0], 
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };


  getTabImages = (tab) => {
    const tabData = {
      ADAS: [
        {
          src: adas1,
          label:'ADAS',
          title: 'ADAS',
          subtitle: '14 AUTONOMOUS LEVEL-2 FEATURES',
          description: 'Ensure your safety on the open road with the intelligent Autonomous Level – 2 (ADAS) technology. Autonomous Level 2 not only makes driving more comfortable and intuitive, but it also keeps your safety at the forefront.',
        },
        {
          src: adas2,
          label:'Lane Functions',
          title: "LANE FUNCTIONS",
          subtitle: '14 AUTONOMOUS LEVEL-2 FEATURES',
          description: 'The multipurpose camera identifies lane markings and warns you in case the vehicle is deviating from the lane and can even correct your course. It can also actively keep the vehicle in the centre of the lane by controlling the steering.',
        },
        {
          src: adas3,
          label:'Rear Drive Assist',
          title: 'REAR DRIVE ASSIST',
          subtitle: '14 AUTONOMOUS LEVEL-2 FEATURES',
          description: 'Rear Drive Assist functions help in monitoring the surrounding environment of the vehicle at all times to completely eliminate blind spots - be it overtaking vehicles, rear incoming traffic or side-lane strafing. ',
        },
        {
          src: adas4,
          label:'Adaptive Cruise Control (ACC)',
          title: 'ADAPTIVE CRUISE CONTROL (ACC)',
          subtitle: '14 AUTONOMOUS LEVEL-2 FEATURES',
          description: 'Adaptive Cruise Control is an advanced version of cruise control and senses the road ahead. It enables the vehicle to control its acceleration and braking to achieve the desired speed while maintaining a safe distance from cars ahead.',
        }
      ],
      EXTERIOR: [
        {
          src: ext1,
          label:'Bold Celestial Grille',
          title: 'BOLD CELESTIAL GRILLE',
          subtitle: 'STYLED TO PERFECTION',
          description: 'MG Astor comes with a Bold Celestial Grille that is inspired by cosmic lines and forms which adds dynamism to the front of the car.',
        },
        {
          src: ext2,
          label:'Full LED Hawkeye Headlamps',
          title: 'FULL LED HAWKEYE HEADLAMPS',
          subtitle: 'STYLED TO PERFECTION',
          description: 'The Astor gets full LED Hawkeye Headlamps which are flanked by a bold celestial grille.',
        },
        {
          src: ext3,
          label: 'Turbine-Inspired Machined Alloys',
          title:'TURBINE-INSPIRED MACHINED ALLOYS',
          subtitle: 'STYLED TO PERFECTION',
          description: 'MG Astor comes with turbine-inspired machined alloys that provide a better grip for steering and cornering your car smoothly.',
        },
        {
          src: ext4,
          label: 'LED Tail Lamps',
          title:'LED TAIL LAMPS',
          subtitle: 'STYLED TO PERFECTION',
          description: 'MG Astor features sleek LED tail-lamps that provide defined edges to project a wider look.',
        }
      ],
      INTERIOR: [
        {
          src: int1,
          label:'Dual Tone Sangria Red Interior Theme',
          title: 'DUAL TONE SANGRIA RED INTERIOR THEME',
          subtitle: 'OPULENT TO THE CORE',
          description: 'Indulge in luxury every time you step inside MG Astor, with its premium dual-tone Sangria Red interior theme.',
        },
        {
          src: int2,
          label:'Full Digital Cluster With 17.78 cm Embedded LCD Screen',
          title: "FULL DIGITAL CLUSTER WITH 17.78 cm EMBEDDED LCD SCREEN",
          subtitle: 'OPULENT TO THE CORE',
          description: 'MG Astor comes with a fully digital cluster with a seven-inch embedded LCD screen. ',
        },
        {
          src: int3,
          label:'Premium Leather# Layered Interiors',
          title: 'PREMIUM LEATHER# LAYERED INTERIORS',
          subtitle: 'OPULENT TO THE CORE',
          description: 'Get draped in premium aesthetics. The luxurious artificial leathered interiors enhance your overall style while providing you with ultra-comfort.',
        },
        {
          src: int4,
          label:'25.7cm HD Touchscreen',
          title: '25.7cm HD TOUCHSCREEN',
          subtitle: 'OPULENT TO THE CORE',
          description: 'The MG Astor comes equipped with a 25.7cm HD Touchscreen which ensures your driving experience is filled with excitement. ',
        }
      ],
      SAFETY: [
        {
          src: safe1,
          label:'Front Fog Lamp With Cornering Assist',
          title: 'FRONT FOG LAMP WITH CORNERING ASSIST',
          subtitle: 'SAFE FOR THE ENVIRONMENT. SAFE FOR YOU.',
          description: 'Illuminate your path with Astor’s front fog lamps that come with cornering assistance to provide additional illumination on your journeys.​',
        },
        {
          src: safe2,
          label:'Rear Fog Lamps',
          title: 'REAR FOG LAMPS',
          subtitle: 'DESIGNED TO KEEP YOU SAFE, ALWAYS',
          description: 'To further raise the bar on safety, the vehicle offers rear fog lamps.',
        },
        {
          src: safe3,
          label: 'Heated ORVMs',
          title:'HEATED ORVMs',
          subtitle: 'DESIGNED TO KEEP YOU SAFE, ALWAYS',
          description: 'The heated ORVMs assures safe driving and clear vision in humid weather conditions and extreme winters.',
        },
      ],
      COMFORT: [
        {
          src: comf1,
          label:'Electronic Power Steering With Mode Adjust',
          title: 'ELECTRONIC POWER STEERING WITH MODE ADJUST',
          subtitle: 'CRAFTED FOR COMFORT',
          description: 'The Astor is equipped with 3 steering modes - Urban, Normal and Dynamic for effortless manoeuvring on the roads. ',
        },
        {
          src: comf2,
          label:'Panoramic Skyroof',
          title: "PANORAMIC SKYROOF",
          subtitle: 'CRAFTED FOR COMFORT',
          description: 'Get used to the gorgeous sunrises and breathtaking starry nights with the best-in-segment panoramic skyroof in the MG Astor. ',
        },
        {
          src: comf3,
          label:'Push Button Engine (Start/Stop with Smart Entry)',
          title: 'PUSH BUTTON ENGINE(Start/Stop with Smart Entry)',
          subtitle: 'CRAFTED FOR COMFORT',
          description: 'Gone are the days of fumbling with traditional ignition keys. The Push Button feature provides a seamless and instant starting and stopping process, allowing you to hit the road with ease.',
        },
        {
          src: comf4,
          label:'6 Way Power Adjustment Seat',
          title: '6 WAY POWER ADJUSTMENT SEAT',
          subtitle: 'CRAFTED FOR COMFORT',
          description: 'Indulge in liberating comfort and convenience with Astor’s 6-way adjustable driver seat.​',
        }
      ],
      "i-SMART": [
        {
          src: i1,
          label:'Digital Key With Bluetooth Technology',
          title: 'DIGITAL KEY WITH BLUETOOTH TECHNOLOGY',
          subtitle: 'i-SMART NEXT GEN TECHNOLOGY',
          description: 'The next revolution is here. Unlock your car with just a tap with the high-tech Astor Digital Key that comes with Bluetooth Technology.',
        },
        {
          src: i2,
          label:'Inbuilt Jio Saavn App',
          title: 'INBUILT JIO SAAVN APP',
          subtitle: 'i-SMART NEXT GEN TECHNOLOGY',
          description: 'Stream. Play. Groove. MG Astor features an inbuilt JioSaavn app to provide you with endless entertainment on your journeys. ',
        },
        {
          src: i3,
          label: 'In Car Remote Control',
          title:'IN CAR REMOTE CONTROL',
          subtitle: 'i-SMART NEXT GEN TECHNOLOGY',
          description: 'MG’s i-Smart system allows direct remote control of a range of key functions all from a simple-to-use smartphone app, including being able to pre-heat the car to your desired temperature before entering. ',
        },
        {
          src: i4,
          label: 'Live Location Sharing With Friends And Family',
          title:'LIVE LOCATION SHARING WITH FRIENDS AND FAMILY',
          subtitle: 'i-SMART NEXT GEN TECHNOLOGY',
          description: 'Locating your Astor is easier than you think. Share the live location of the SUV from your mobile and help anyone to find the SUV.',
        }
      ],
    };
    
    return tabData[tab] || [];
  };

  handleTabChange = (tab) => {
    const images = this.getTabImages(tab);
    this.setState({
      selectedTab: tab,
      selectedImage: images[0], // Default to first image of the selected tab
    });
  };

  handleImageClick = (image) => {
    this.setState({ selectedImage: image });
  };

  renderContent = () => {
    const { selectedTab, selectedImage } = this.state;
    const images = this.getTabImages(selectedTab);
  
    return (
      <Box sx={{ width: '80%', height:'100%',
     
      }}>
        <Box
          sx={{
            height:'90%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: { xs: 'center', md: 'start' },
            gap: '20px',
            flexWrap: 'no-wrap',
            marginLeft: { xs: '-5px', md: '0px' },
          }}
        >
          <img
            src={selectedImage.src}
            style={{
              borderRadius: '10px',
              width: '97vw',
              maxWidth: '500px',
              height: 'auto',
            }}
            alt={selectedImage.title}
          />
  
          <style>
            {`
              @media (max-width: 768px) { /* Tablet and smaller */
                img {
                  width: 80vw; /* Adjust the width for tablet view */
                  max-width: 100%; /* Ensure it doesn't exceed container width */
                }
              }
  
              @media (max-width: 480px) { /* Mobile view */
                img {
                  width: 90vw; /* Increase width for mobile view */
                  max-width: 100%;
                }
              }
            `}
          </style>
  
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { md: 'space-around', xs: 'start', sm: 'start' },
              alignItems: 'start',
              width: '100%',
              maxWidth: '500px',
              marginLeft: { xs: '-25px', md: '0px' }
            }}
          >
            <Typography
              variant="h5"
              sx={{
                width: { xs: '90%', md: '510px' },
                fontFamily: 'GillSansRegular, Arial, sans-serif',
                color: '#000',
                fontSize: { xs: '4vw', md: '1.5625vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.title}
            </Typography>
            <Typography
              sx={{
                width: { xs: '90%', md: '330px', sm: '90%' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                color: '#6e6e6e',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.5vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.subtitle}
            </Typography>
            <Typography
              sx={{
                width: { xs: '100%', md: '460px', lg: '450px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '1.30208vw',
                '@media (min-width: 1024px) and (max-width: 1139px)': {
                  width: '280px',
                },
              }}
            >
              {selectedImage.description}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: { xs: 'center', md: 'flex-start' },
            gap: '10px',
            marginTop: { xs: '10px', md: '50px', lg: '20px' },
            marginLeft: { xs: '-10px', md: '0px' },
          }}
        >
          {images.map((img, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { md: 'center', xs: 'start' }
              }}
            >
              <img
                src={img.src}
                style={{
                  borderRadius: '10px',
                  width: '20vw',
                  maxWidth: '250px',
                  height: 'auto',
                  marginTop: { xs: '10px', md: '40px', sm: '20px' }
                }}
                alt={img.label}
                onClick={() => this.handleImageClick(img)}
              />
              <Typography
                sx={{
                  fontFamily: 'Roboto-Medium, Arial, sans-serif',
                  fontSize: { xs: '3.5vw', md: '1.04167vw' },
                  color: '#000',
                  paddingTop: '1.04167vw',
                  margin: 0,
                  fontWeight: '550',
                  display: { md: 'flex', xs: 'none' },
                  textAlign: { xs: 'none', md: 'left' },
                  width: '100%'
                }}
              >
                {img.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };
  


  render() {
    const { selectedTab, isMobile } = this.state;
    const tabs = ['ADAS','EXTERIOR', 'INTERIOR', 'SAFETY',  'COMFORT', 'i-SMART'];

    return (
      <Box
        id="features"
        sx={{
          height: '100%',
          width: '100%',
          // minHeight: '85vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '6vw', md: '2.08333vw' }, 
            color: '#cf2230',
            fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
            paddingBottom: '3.125vw',
            fontWeight: '400',
            marginTop:'20px'
          }}
        >
          A Car That Has It All
        </Typography>

        {isMobile ? (
          <FormControl fullWidth sx={{ width: '80%', marginBottom: '20px', }}>
            <Select
              value={selectedTab}
              style={{fontWeight:'700'}}
              onChange={(e) => this.handleTabChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Tab Selection' }}
            >
              {tabs.map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              height: '15px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            {tabs.map((tab) => (
              <Button
                key={tab}
                onClick={() => this.handleTabChange(tab)}
                sx={{
                  fontFamily: 'GillSansRegular, Arial, sans-serif',
                  fontSize: { xs: '4vw', md: '1.25vw' }, 
                  color: selectedTab === tab ? 'white' : 'black',
                  backgroundColor: selectedTab === tab ? '#6B6F73' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#6B6F73',
                    color: 'white',
                  },
                }}
              >
                {tab}
              </Button>
            ))}
          </Box>
        )}

        <Divider sx={{ width: '80%', display:{md:'flex',xs:'none'},border: '4px solid #6B6F73', margin: '20px 0' }} />

        {this.renderContent()}

        {/* <Button
          sx={{
            backgroundColor: '#CF2230',
            color: 'white',
            fontWeight: '700',
            fontFamily: 'GillSansSemiBold , sans-serif',
            fontSize: { xs: '3.5vw', md: '0.95vw',sm:'2.2vw' }, 
            cursor: 'pointer',
            marginTop:'30px',
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
            },
          }}
        >
          Virtual Showroom
        </Button> */}
      </Box>
    );
  }
}

export default Features;
