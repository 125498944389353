import React, { Component } from "react";
import { Box, Typography, Button, Divider,Select,FormControl,MenuItem } from '@mui/material';
import ext1 from '../../../../../images/ext1.webp';
import ext2 from '../../../../../images/ext2.webp';
import ext3 from '../../../../../images/ext3.webp';
import ext4 from '../../../../../images/ext4.webp';
import int1 from '../../../../../images/int1.webp'; 
import int2 from '../../../../../images/int2.webp';
import int3 from '../../../../../images/int3.webp';
import int4 from '../../../../../images/int4.webp';
import safe1 from '../../../../../images/safe1.webp';
import safe2 from '../../../../../images/safe2.webp';
import safe3 from '../../../../../images/safe3.webp';
import safe4 from '../../../../../images/safe4.webp';
import adas1 from '../../../../../images/adas1.webp';
import adas2 from '../../../../../images/adas2.jpg';
import adas3 from '../../../../../images/adas3.webp';
import adas4 from '../../../../../images/adas4.jpg';
import i1 from '../../../../../images/i1.webp';
import i2 from '../../../../../images/i2.webp';
import i3 from '../../../../../images/i3.webp';
import i4 from '../../../../../images/i4.webp';
import comf1 from '../../../../../images/comfort1.webp';
import comf2 from '../../../../../images/comfort2.webp';
import comf3 from '../../../../../images/comfort3.webp';
import comf4 from '../../../../../images/comfort4.jpg';
import ser1 from '../../../../../images/shield1.webp'
import ser2 from '../../../../../images/sheild2.webp'
import ser3 from '../../../../../images/sheild3.webp'


class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'EXTERIOR',
      isMobile: window.innerWidth <= 768, // Detect initial mobile view
      selectedImage: this.getTabImages('EXTERIOR')[0], 
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };


  getTabImages = (tab) => {
    const tabData = {
      EXTERIOR: [
        {
          src: ext1,
          label:'Exterior',
          title: 'EXTERIOR',
          subtitle: 'ICONIC DESIGN & POWERFUL PRESENCE',
          description: 'The Hector announces its arrival with a bold design statement that complements your desire for the extraordinary. Witness a daring new front design and a sleek rear cluster that add to its evocative character and commanding presence on the road.',
        },
        {
          src: ext2,
          label:'Argyle-Inspired Diamond Mesh Grille',
          title: 'ARGYLE-INSPIRED DIAMOND MESH GRILLE',
          subtitle: 'ICONIC DESIGN & POWERFUL PRESENCE',
          description: 'The Hector announces its arrival with a bold design statement that complements your desire for the extraordinary. Witness a daring new front design and a sleek rear cluster that add to its evocative character and commanding presence on the road.',
        },
        {
          src: ext3,
          label: 'R18 Dual Tone Machined Alloy Wheels',
          title:'R18 DUAL TONE MACHINED ALLOY WHEELS',
          subtitle: 'ICONIC DESIGN & POWERFUL PRESENCE',
          description: 'The Hector announces its arrival with a bold design statement that complements your desire for the extraordinary. Witness a daring new front design and a sleek rear cluster that add to its evocative character and commanding presence on the road',
        },
        {
          src: ext4,
          label: 'LED Connected Blade Tail Lamps',
          title:'LED CONNECTED BLADE TAIL LAMPS',
          subtitle: 'ICONIC DESIGN & POWERFUL PRESENCE',
          description: 'The Hector announces its arrival with a bold design statement that complements your desire for the extraordinary. Witness a daring new front design and a sleek rear cluster that add to its evocative character and commanding presence on the road',
        }
      ],
      INTERIOR: [
        {
          src: int1,
          label:'Full Dashboard',
          title: 'FULL DASHBOARD',
          subtitle: 'A PERFECT SYMPHONY OF COMFORT AND LUXURY',
          description: 'Every little detail in the Next-Gen Hector is crafted to raise the bar of driving luxury – from the highly comfortable ventilated seats to the plush dual-tone interiors, and even the ambience that can be set to match your mood, and more.',
        },
        {
          src: int2,
          label:'Portrait Infotainment System',
          title: "INDIA'S LARGEST 35.56 cm(14 INCH) HD PORTRAIT INFOTAINMENT SYSTEM",
          subtitle: 'A PERFECT SYMPHONY OF COMFORT AND LUXURY',
          description: 'Visually immersive and elegantly crafted – the larger-than-life 14-inch infotainment unit welcomes you with its sheer cinematic grandeur and cockpit like feel for an in-car experience that’s at whole new level.',
        },
        {
          src: int3,
          label:'Front Ventilated Seats',
          title: 'FRONT VENTILATED SEATS',
          subtitle: 'A PERFECT SYMPHONY OF COMFORT AND LUXURY',
          description: 'Every little detail in the Next-Gen Hector is crafted to raise the bar of driving luxury – from the highly comfortable ventilated seats to the plush dual-tone interiors, and even the ambience that can be set to match your mood, and more.',
        },
        {
          src: int4,
          label:'Dual Tone Oak White & Black Interior Theme - Brushed Metal Finish',
          title: 'DUAL TONE OAK WHITE AND BLACK INTERIOR THEME - BRUSHED METAL FINISH',
          subtitle: 'A PERFECT SYMPHONY OF COMFORT AND LUXURY',
          description: 'Every little detail in the Next-Gen Hector is crafted to raise the bar of driving luxury – from the highly comfortable ventilated seats to the plush dual-tone interiors, and even the ambience that can be set to match your mood, and more.',
        }
      ],
      SAFETY: [
        {
          src: safe1,
          label:'Steel Body',
          title: 'STEEL BODY',
          subtitle: 'AN IMPRESSIVE RANGE OF NEXT-GEN SAFETY FEATURES',
          description: 'The Hector is not only built tough but it also ensures complete peace of mind for you and your loved ones – thanks to a wide range of advanced protective features that reinforce your safety both on and off the road.​',
        },
        {
          src: safe2,
          label:'360 Around View (HD) Camera System',
          title: '360 AROUND VIEW (HD) CAMERA SYSTEM',
          subtitle: 'AN IMPRESSIVE RANGE OF NEXT-GEN SAFETY FEATURES',
          description: 'The Hector is not only built tough but it also ensures complete peace of mind for you and your loved ones – thanks to a wide range of advanced protective features that reinforce your safety both on and off the road.',
        },
        {
          src: safe3,
          label: '6 Airbags Front, Side Curtain Airbags',
          title:'6 AIRBAGS FRONT, SIDE CURTAIN AIRBAGS',
          subtitle: 'AN IMPRESSIVE RANGE OF NEXT-GEN SAFETY FEATURES',
          description: 'Airbags save lives. And we can’t stress this enough. With 6 Airbags that includes dual front airbags, front seat side impact and side curtain airbags that work together with the seatbelts, we made sure that MG Hector is always armoured to protect you.',
        },
        {
          src: safe4,
          label: 'Electronic Stability Program',
          title:'ELECTRONIC STABILITY PROGRAM',
          subtitle: 'AN IMPRESSIVE RANGE OF NEXT-GEN SAFETY FEATURES',
          description: 'Electronic Stability Program improves the car’s stability by detecting and reducing lack of traction. ESP detects loss of steering control and automatically applies the brakes to help steer the car where the driver intends to go. ',
        }
      ],
      ADAS: [
        {
          src: adas1,
          label:'Adas Autonomous Level-2 Features',
          title: 'ADAS AUTONOMOUS LEVEL-2 FEATURES',
          subtitle: 'YOUR JOURNEYS MADE SAFER WITH ADAS',
          description: 'Your Hector is built with one thing in mind – to raise your driving experience and safety with the most cutting-edge technology on offer.  The 11 Autonomous Level-2 features of the Advanced Driver Assistance System (ADAS) are a giant leap in this direction, which keep an eye out for you at every turn and straight.',
        },
        {
          src: adas2,
          label:'Adaptive Cruise Control (ACC)',
          title: "ADAPTIVE CRUISE CONTROL (ACC)",
          subtitle: 'YOUR JOURNEYS MADE SAFER WITH ADAS',
          description: 'Adaptive Cruise Control senses and monitors the road ahead and automatically accelerates or decelerates for maintaining a proper distance from the vehicle in front. Thanks to the Bend Cruise Assistance (BCA), the vehicle automatically decelerates when entering a curve based on your driving speed and curve radius.',
        },
        {
          src: adas3,
          label:'Traffic Jam Assist (TJA)',
          title: 'TRAFFIC JAM ASSIST (TJA)',
          subtitle: 'YOUR JOURNEYS MADE SAFER WITH ADAS',
          description: 'Traffic Jam Assist (TJA) works in conjunction with ACC and LKA to let you cruise through heavy traffic with complete safety and comfort. By keeping the vehicle in middle of the lane and maintaining a safe distance from the vehicle in front, it takes you through jams securely and comfortably.',
        },
        {
          src: adas4,
          label:'Lane Departure Warning (LDW)',
          title: 'LANE DEPARTURE WARNING (LDW)',
          subtitle: 'YOUR JOURNEYS MADE SAFER WITH ADAS',
          description: 'If, the vehicle is ‘unintentionally’ deviating from its lane without giving an indicator, to keep you inside the lane, the Lane Departure Warning (LDW) function identifies lane markings on the road and warns the driver through visual and acoustic signals or steering wheel vibrations to ensure you stay inside the lane.',
        }
      ],
      "i-SMART": [
        {
          src: i1,
          label:'Digital Bluetooth Car Key',
          title: 'DIGITAL BLUETOOTH CAR KEY',
          subtitle: 'TECHNOLOGY THAT EMPOWERS YOU',
          description: 'Your Next-Gen Hector comes with an innovative Digital Bluetooth® Key and Key Sharing capability. In case of emergency or loss of key, you can use the Digital Key to lock, unlock, start and drive your Hector. With the Key Sharing function, you can share an additional key with up to two people.',
        },
        {
          src: i2,
          label:'100+ Voice Commands',
          title: '100+ VOICE COMMANDS',
          subtitle: 'TECHNOLOGY THAT EMPOWERS YOU',
          description: 'Talk to your car, literally, with more than 100+ voice commands and 50+ Hinglish Voice Commands like Sunroof Open/Close, AC Temperature Change, to support you.',
        },
        {
          src: i3,
          label: 'Sunroof Touch Control',
          title:'SUNROOF TOUCH CONTROL',
          subtitle: 'TECHNOLOGY THAT EMPOWERS YOU',
          description: 'Have the best views at your fingertips. Conveniently set the percentage opening limit of your Next-Gen Hector’s sunroof, between 0% to 100%, directly from the touchscreen!',
        },
        {
          src: i4,
          label: 'i-Call / e-Call',
          title:'I-CALL / E- CALL',
          subtitle: 'TECHNOLOGY THAT EMPOWERS YOU',
          description: 'With the E-Call system, an auto activated emergency call is made to the car head unit in case of any mishap followed by primary number & emergency number in case of no response.Get road side/ general assistance from the Pulse Hub at the touch of the I-Call button.',
        }
      ],
      COMFORT: [
        {
          src: comf1,
          title:'AUTOMATIC POWERED TAILGATE',
          subtitle: 'THOUGHTFULLY DESIGNED FOR UNRIVALLED COMFORT',
          label: 'Automatic Powered Tailgate',
          description: 'The Next-Gen Hector has been intuitively designed keeping your comfort and convenience in mind. Once you step inside, you will find that everything you need to make your journey a relaxed drive is thoughtfully crafted and within easy reach.',
        },
        {
          src: comf2,
          label:'Intelligent Turn Indicator',
          title: "INTELLIGENT TURN INDICATOR",
          subtitle: 'THOUGHTFULLY DESIGNED FOR UNRIVALLED COMFORT',
          description: 'The Next-Gen Hector has been intuitively designed keeping your comfort and convenience in mind. Once you step inside, you will find that everything you need to make your journey a relaxed drive is thoughtfully crafted and within easy reach.',
        },
        {
          src: comf3,
          label:'Wireless Charger',
          title: 'WIRELESS CHARGER',
          subtitle: 'THOUGHTFULLY DESIGNED FOR UNRIVALLED COMFORT',
          description: 'The Next-Gen Hector has been intuitively designed keeping your comfort and convenience in mind. Once you step inside, you will find that everything you need to make your journey a relaxed drive is thoughtfully crafted and within easy reach.',
        },
        {
          src: comf4,
          label:'Front Powered Seats',
          title: 'FRONT POWERED SEATS',
          subtitle: 'THOUGHTFULLY DESIGNED FOR UNRIVALLED COMFORT',
          description: 'Every little detail in the Next-Gen Hector is crafted to raise the bar of driving​ luxury – from the highly comfortable ventilated seats to the plush dual-tone interiors. The driver also gets a 6-way adjustable seat and the co-driver gets a 4-way adjustable seat. ​',
        }
      ],
      SERVICE: [
        {
          src: ser1,
          label:'Personalised Ownership Package',
          title: 'PESONALISED OWNERSHIP PACKAGE',
          subtitle: 'A COMPLETE CARE PACKAGE',
          description: 'The MG Shield gives you 65+ combinations to choose from. This helps you pick the kind of assistance that best suits your ownership experience. ',
        },
        {
          src: ser2,
          label:'MG Shield',
          title: 'MG SHIELD',
          subtitle: 'A COMPLETE CARE PACKAGE',
          description: ['The 3-3-3 MG Shield package gives you',
            ' 3 years unlimited Km warranty',
            ' 3 years unlimited Km roadside assistance',
            ' 3 free labour services'],
        },
        {
          src: ser3,
          label: 'Sunroof Touch Control',
          title:'SUNROOF TOUCH CONTROL',
          subtitle: 'TECHNOLOGY THAT EMPOWERS YOU',
          description: 'Have the best views at your fingertips. Conveniently set the percentage opening limit of your Next-Gen Hector’s sunroof, between 0% to 100%, directly from the touchscreen!',
        },
        
      ],
    };
    
    return tabData[tab] || [];
  };

  handleTabChange = (tab) => {
    const images = this.getTabImages(tab);
    this.setState({
      selectedTab: tab,
      selectedImage: images[0], // Default to first image of the selected tab
    });
  };

  handleImageClick = (image) => {
    this.setState({ selectedImage: image });
  };

  renderContent = () => {
    const { selectedTab, selectedImage } = this.state;
    const images = this.getTabImages(selectedTab);
  
    return (
      <Box sx={{ width: '80%', height: { md: '100%', xs: '100%', sm: '100%',lg:'100%'},
      }}>
        <Box
          sx={{
            height:'90%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: { xs: 'center', md: 'start' },
            gap: '20px',
            flexWrap: 'no-wrap',
            marginLeft: { xs: '-5px', md: '0px' },
            
          }}
        >
          <img
            src={selectedImage.src}
            style={{
              borderRadius: '10px',
              width: '97vw',
              maxWidth: '500px',
              height: 'auto',
            }}
            alt={selectedImage.title}
          />
  
          <style>
            {`
              @media (max-width: 768px) { /* Tablet and smaller */
                img {
                  width: 80vw; /* Adjust the width for tablet view */
                  max-width: 100%; /* Ensure it doesn't exceed container width */
                }
              }
  
              @media (max-width: 480px) { /* Mobile view */
                img {
                  width: 90vw; /* Increase width for mobile view */
                  max-width: 100%;
                }
              }
            `}
          </style>
  
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { md: 'space-around', xs: 'start', sm: 'start' },
              alignItems: 'start',
              width: '100%',
              maxWidth: '500px',
              marginLeft: { xs: '-25px', md: '0px' }
            }}
          >
            <Typography
              variant="h5"
              sx={{
                width: { xs: '90%', md: '510px' },
                fontFamily: 'GillSansRegular, Arial, sans-serif',
                color: '#000',
                fontSize: { xs: '4vw', md: '1.5625vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.title}
            </Typography>
            <Typography
              sx={{
                width: { xs: '90%', md: '330px', sm: '90%' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                color: '#6e6e6e',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.5vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.subtitle}
            </Typography>
            <Typography
              sx={{
                width: { xs: '100%', md: '460px', lg: '450px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '1.30208vw',
                '@media (min-width: 1024px) and (max-width: 1139px)': {
                  width: '280px',
                },
              }}
            >
              {selectedImage.description}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: { xs: 'center', md: 'flex-start' },
            gap: '10px',
            marginTop: { xs: '10px', md: '50px', lg: '20px' },
            marginLeft: { xs: '-10px', md: '0px' },
          }}
        >
          {images.map((img, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { md: 'center', xs: 'start' }
              }}
            >
              <img
                src={img.src}
                style={{
                  borderRadius: '10px',
                  width: '20vw',
                  maxWidth: '250px',
                  height: 'auto',
                  marginTop: { xs: '10px', md: '40px', sm: '20px' }
                }}
                alt={img.label}
                onClick={() => this.handleImageClick(img)}
              />
              <Typography
                sx={{
                  fontFamily: 'Roboto-Medium, Arial, sans-serif',
                  fontSize: { xs: '3.5vw', md: '1.04167vw' },
                  color: '#000',
                  paddingTop: '1.04167vw',
                  margin: 0,
                  fontWeight: '550',
                  display: { md: 'flex', xs: 'none' },
                  textAlign: { xs: 'none', md: 'left' },
                  width: '100%'
                }}
              >
                {img.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };
  


  render() {
    const { selectedTab, isMobile } = this.state;
    const tabs = ['EXTERIOR', 'INTERIOR', 'SAFETY', 'ADAS', 'i-SMART', 'COMFORT', 'SERVICE'];

    return (
      <Box
        id="features"
        sx={{
          height: 'auto',
          width: '100%',
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '6vw', md: '2.08333vw' }, 
            color: '#cf2230',
            fontFamily: 'GillSansSemiBold, Arial, sans-serif',
            paddingBottom: '3.125vw',
            fontWeight: '400',
            marginTop:'20px'
          }}
        >
          A Car That Has It All
        </Typography>

        {isMobile ? (
          <FormControl fullWidth sx={{ width: '80%', marginBottom: '20px', }}>
            <Select
              value={selectedTab}
              style={{fontWeight:'700'}}
              onChange={(e) => this.handleTabChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Tab Selection' }}
            >
              {tabs.map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              height: '15px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            {tabs.map((tab) => (
              <Button
                key={tab}
                onClick={() => this.handleTabChange(tab)}
                sx={{
                  fontFamily: 'GillSansRegular, Arial, sans-serif',
                  fontSize: { xs: '4vw', md: '1.25vw' }, 
                  color: selectedTab === tab ? 'white' : 'black',
                  backgroundColor: selectedTab === tab ? '#E32735' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#E32735',
                    color: 'white',
                  },
                }}
              >
                {tab}
              </Button>
            ))}
          </Box>
        )}

        <Divider sx={{ width: '80%', display:{md:'flex',xs:'none'},border: '4px solid #E32735', margin: '20px 0' }} />

        {this.renderContent()}

        {/* <Button
          sx={{
            backgroundColor: '#CF2230',
            color: 'white',
            fontWeight: '700',
            fontFamily: 'GillSansSemiBold , sans-serif',
            fontSize: { xs: '3.5vw', md: '0.95vw',sm:'2.2vw' }, 
            cursor: 'pointer',
            marginTop:'30px',
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
            },
          }}
        >
          Virtual Showroom
        </Button> */}
      </Box>
    );
  }
}

export default Features;
