import React, { Component } from "react";
import { connect } from "react-redux";
import ContactDetails from "../Components/ContactDetails";

import {
  viewAllContactUs,
  uploadImaes,
  updateContactStatus,
  uploadupdatesImages,
  deleteContact,
} from "../action";
export class Controller extends Component {
  render() {
    return <ContactDetails {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllContactUs: (
      token,
      user_id,
      searchInput,
      page,
      pageSize,
      selectedDate,
      selectedStatus
    ) => {
      dispatch(
        viewAllContactUs(
          token,
          user_id,
          searchInput,
          page,
          pageSize,
          selectedDate,
          selectedStatus
        )
      );
    },

   
    updateContactStatus: (token, user_id, id, status, page, pageSize) => {
      dispatch(updateContactStatus(token, user_id, id, status, page, pageSize));
    },
    deleteContact: (token, user_id, id, page, pageSize) => {
      dispatch(deleteContact(token, user_id, id, page, pageSize));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
